import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial State
 * @type {import('types/slices/review').InitialState}
 */
const initialState = {
  reviewInfo: {
    isLoaded: false,
    classId: 0,
  },

  statis: {},

  selectedReview: {
    id: 0,
    version: 2,
    pros_content: '',
    cons_content: '',
    suggestions: '',
    media_id: '',
    thumbnail_link: '',
    photo_list: [],
    progress: 0,
    blocked: false,
  },

  commentList: [],

  myRecentReview: {},

  suggestions: {},

  recommendClassList: [],

  point: {
    month_expire_point: 0,
    review_point: 0,
    total_point: 0,
  },

  userReviewList: {
    reviewed_list: [],
    not_reviewed_list: [],
  },

  classReviewList: [],
  classReviewListForPaging: [],
};

/**
 * Slice
 */
const userReviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    /** @dispatch 단일 리뷰 초기화 */
    initReview(state) {
      state.reviewInfo = initialState.reviewInfo;
      state.selectedReview = initialState.selectedReview;
      state.commentList = initialState.commentList;
      state.suggestions = initialState.suggestions;
    },
    /** @dispatch 클래스 리뷰 목록 초기화 (페이지 방식) */
    initClassReviewListForPaging(state) {
      state.classReviewListForPaging = [];
    },
    /** @dispatch 리뷰 정보 갱신 */
    updateReviewInfo(state, action) {
      state.reviewInfo = {
        ...state.reviewInfo,
        ...action.payload,
      };
    },
    /** @dispatch 단일 리뷰 갱신 */
    updateSelectedReview(state, action) {
      state.selectedReview = {
        ...state.selectedReview,
        ...action.payload,
      };
    },
    /** @dispatch 클래스 리뷰 집계 갱신 */
    setReviewStatis(state, action) {
      state.statis = action.payload;
    },
    /** @dispatch 리뷰 포인트 갱신 */
    setReviewPoint(state, action) {
      state.point = action.payload;
    },
    /** @dispatch 나의 최근 클래스 수강권 ID 맟 리뷰 작성여부 스테이트 갱신 */
    setMyReviewState(state, action) {
      state.myRecentReview = action.payload;
    },
    /** @dispatch 리뷰 건의사항/강사댓글 갱신 */
    setReviewSuggestions(state, action) {
      state.suggestions = action.payload;
    },
    /** @dispatch 사용자 리뷰 목록 갱신 */
    setUserReviewList(state, action) {
      state.userReviewList = action.payload;
    },
    /** @dispatch 클래스 리뷰 목록 갱신 */
    setClassReviewList(state, action) {
      state.classReviewList = action.payload;
    },
    /** @dispatch 클래스 리뷰 목록 갱신 (페이지 방식)*/
    setClassReviewListForPaging(state, action) {
      state.classReviewListForPaging = action.payload;
    },
    /** @dispatch 클래스 리뷰 목록 추가 */
    pushClassReviewList(state, action) {
      state.classReviewList = [...state.classReviewList, ...action.payload];
    },
    /** @dispatch 클래스 상세 데이터 갱신 */
    updateClassReviewDetail(state, action) {
      state.selectedReview = action.payload;

      const updateList = state.classReviewList.map(v =>
        v.id === action.payload.id
          ? Object.assign({}, v, {
              ...action.payload,
            })
          : v,
      );

      state.classReviewList = updateList;
    },
    /** @dispatch 리뷰 추천 클래스 갱신 */
    setRecommendClassList(state, action) {
      state.recommendClassList = action.payload;
    },
    /** @dispatch 댓글 목록 정보 갱신 */
    setCommentList(state, action) {
      state.commentList = action.payload;
    },
    /** @dispatch 댓글 목록 정보 추가 */
    pushCommentList(state, action) {
      state.commentList = [...state.commentList, ...action.payload];
    },
    /** @dispatch 댓글 목록에 새로운 댓글 추가 */
    unshiftCommentList(state, action) {
      state.commentList = [action.payload, ...state.commentList];
    },
  },
});

export const {
  initReview,
  initClassReviewListForPaging,
  updateReviewInfo,
  updateSelectedReview,
  setReviewStatis,
  setReviewPoint,
  setMyReviewState,
  setReviewSuggestions,
  setUserReviewList,
  setClassReviewList,
  setClassReviewListForPaging,
  pushClassReviewList,
  updateClassReviewDetail,
  setRecommendClassList,
  updateLikeCount,
  setCommentList,
  pushCommentList,
  unshiftCommentList,
} = userReviewSlice.actions;

export default userReviewSlice.reducer;
