import styled, { css } from 'styled-components';
// components
import ClassCover from 'components/atoms/photos/ClassCover';
// hooks
import { useSelector } from 'hooks/common/useStore';
import useCommonBridge from 'hooks/bridge/useCommonBridge';
// styles
import * as typography from 'cds/typography';
import { palette, mediaQuery } from 'cds/styles';

/**
 * 클래스 아이템 column 타입 카드
 * @param {object} props
 * @param {import('types/slices/main').RecommendClass} props.data 클래스 정보
 */
const OpenItem = ({ data }) => {
  const userInfo = useSelector(state => state.user.info);

  const { goToClassDetail } = useCommonBridge();

  const linkDelegator = () => {
    if (data.open_flag === 'N') {
      window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/open_class/${data.class_id}`;
      return;
    }

    if (data.link) {
      window.location.href = data.link;
      return;
    }

    if (!userInfo.id) {
      window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/classDetail/${data.class_id}`;
      return;
    }

    return goToClassDetail(data.class_id);
  };

  return (
    <Item onClick={linkDelegator}>
      <ClassCover ratio="60.6061%" src={data.class_photo} />

      <TitleWrapper>
        <Title>{data.class_name}</Title>
      </TitleWrapper>
    </Item>
  );
};

const skeleton = css`
  background-color: ${palette.grey010};
  border-radius: 4px;
`;

const Item = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  border-radius: 8px;

  &:active {
    background-color: ${palette.grey010};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  overflow: hidden;
`;

const Title = styled.p`
  display: block;
  display: -webkit-box;
  position: relative;
  padding: 0;
  margin-bottom: 4px;
  ${typography.subtitle4_medium}
  color: ${palette.grey080};
  white-space: break-spaces;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  &::after {
    content: '...';
    text-align: right;
    bottom: 0;
    right: 0;
    width: 25%;
    display: block;
    position: absolute;
    height: calc(1em * 1.2);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%);
  }

  @supports (-webkit-line-clamp: 2) {
    &::after {
      display: none;
    }
  }

  &:empty {
    ${skeleton};
    height: 20px;
  }

  ${mediaQuery.large} {
    font-size: 15px;
  }
`;

export default OpenItem;
