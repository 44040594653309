import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial State
 * @type {import('types/slices/alarm').InitialState}
 */
const initialState = {
  selectedClassId: null,
  selectedTime: {
    days: {
      sun: 1,
      mon: 1,
      tue: 1,
      wed: 1,
      thu: 1,
      fri: 1,
      sat: 1,
    },
    ampm: 'PM',
    hour: 8,
    minute: 0,
  },
  isOn: true,
};

/**
 * Slice
 */
const alarmSlice = createSlice({
  name: 'alarm',
  initialState,
  reducers: {
    /** @dispatch 알람 클래스 선택 */
    updateSelectedClassId(state, action) {
      state.selectedClassId = action.payload;
    },
    /** @dispatch 알람 시간 선택 */
    updateSelectedTime(state, action) {
      state.selectedTime = { ...state.selectedTime, ...action.payload };
    },
    /** @dispatch 알람 여부 */
    toggleAlarm(state, action) {
      if (action.payload !== undefined) {
        state.isOn = action.payload;
      } else {
        state.isOn = !state.isOn;
      }
    },
  },
});

export const { updateClassList, updateSelectedClassId, updateSelectedTime, toggleAlarm } =
  alarmSlice.actions;

export default alarmSlice.reducer;
