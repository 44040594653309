import { useRef } from 'react';
// components
import ColumnItem from 'components/molecules/class/card/ColumnItem';
import OpenItem from 'components/molecules/class/card/OpenItem';
import HorizontalScrollSlider, { Slide } from 'components/molecules/common/HorizontalScrollSlider';

/**
 * 스크롤 슬라이더 클래스 리스트
 * @param {object} props
 * @param {import('types/slices/main').RecommendClass[]} props.classList 클래스 목록
 * @param {boolean} props.showSalePer 할인율 노출 여부
 * @param {boolean} props.showPrice 가격 노출 여부
 * @param {boolean} props.showProfile 프로필 노출 여부
 * @param {object[default|small|medium|large|xlarge]} props.slidePerView 뷰포트에 보여질 슬라이드 비율
 * @param {'Class' | 'Banner' | 'Open'} props.type 클래스카드 타입
 */
const ClassListSlider = ({
  list = [],
  showSalePer,
  showPrice,
  showProfile,
  slidePerView,
  type,
}) => {
  const sliderRef = useRef(null);

  const skeletonArray = new Array(10).fill(0);
  const data = list.length ? list : skeletonArray;

  const Items = data.map((v, i) => {
    const variant = v.image_small ? 'banner' : 'class';
    return (
      <Slide key={`${v.class_id}${i}`} slidePerView={slidePerView} variant={variant}>
        {type === 'Open' && <OpenItem data={v} />}
        {type !== 'Open' && (
          <ColumnItem
            data={v}
            showSalePer={showSalePer}
            showPrice={showPrice}
            showProfile={showProfile}
            variant={variant}
          />
        )}
      </Slide>
    );
  });

  return <HorizontalScrollSlider ref={sliderRef} list={list} item={Items} />;
};

export default ClassListSlider;
