import Script from 'next/script';

/**
 * Naver Analytics
 */

const Snippets = {
  script: ({ id }) => {
    const code = `
      if (!wcs_add) {
        var wcs_add = {};
      }
      wcs_add['wa'] = '${id}';
      if (!_nasa) {
        var _nasa = {};
      }
      if (window.wcs) {
        window.wcs.inflow();
        window.wcs_do(_nasa);
      }
    `;

    return code;
  },
};

const NaverAnalytics = () => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://wcs.naver.net/wcslog.js"
        onLoad={() => {
          const code = Snippets.script({ id: 's_10eb0caebb8' });
          const script = document.createElement('script');
          script.innerHTML = code;
          document.head.insertBefore(script, document.head.childNodes[0]);
        }}
      />
    </>
  );
};

export default NaverAnalytics;
