import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial State
 * @type {import('types/slices/user').InitialState}
 */
const initialState = {
  info: {
    isLoaded: false,
    id: '',
    name: '',
    photo: '',
    email: '',
    phone: '',
    isCoach: false,

    isShowRequirementModal: false,
  },

  profile: {},

  editProfile: {
    userInfo: {},
  },

  subInfo: {
    level_info: {},
  },

  point: {
    list: [],
  },

  classList: {
    registered: [],
    management: [],
  },

  newsCount: {
    chat_unread_count: 0,
    news_unread_count: 0,
  },

  notificationInfo: {
    nextPage: 1,
    total_page: 2,
  },

  notificationList: [],

  recommendClassList: [],

  recentClassList: [],

  bookmarkClassList: [],

  member: {
    follow: [],
    follower: [],
    blocked: [],
  },
};

/*
	Slice
*/
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /** @dispatch 사용자 정보 갱신 */
    updateInfo(state, action) {
      state.info = {
        ...state.info,
        ...action.payload,
        isLoaded: true,
      };
    },
    /** @dispatch 프로필 정보 설정 */
    setProfile(state, action) {
      state.profile = action.payload;
    },
    /** @dispatch 수정페이지 프로필 정보 설정 */
    setEditProfile(state, action) {
      state.editProfile = action.payload;
      state.info = {
        ...state.info,
        photo: action.payload.userInfo.photo[0].photo_url,
      };
    },
    /** @dispatch 프로필 일상 추가 */
    pushProfileActivity(state, action) {
      state.profile.comment_list = [...state.profile.comment_list, ...action.payload];
    },
    /** @dispatch 프로필 부가 정보 갱신 */
    setSubInfo(state, action) {
      state.subInfo = action.payload;
    },
    /** @dispatch 포인트 리스트 */
    setPointList(state, action) {
      state.point = {
        ...state.point,
        ...action.payload,
        list: [...state.point.list, ...action.payload.list],
      };
    },
    /** @disaptch 사용자 클래스 목록 갱신 */
    updateClassList(state, action) {
      state.classList = { ...state.classList, ...action.payload };
    },
    /** @dispatch 사용자 추천 클래스 목록 설정 */
    setUserRecommendClassList(state, action) {
      state.recommendClassList = action.payload;
    },
    /** @dispatch 사용자 수강 클래스 목록 설정 */
    setRegisteredClassList(state, action) {
      state.classList.registered = action.payload;
    },
    /** @dispatch 확인하지 않은 챗, 알림 카운트 설정 */
    setNewsCount(state, action) {
      state.newsCount = action.payload;
    },
    /** @dispatch 알림 정보 설정 */
    setNotificationInfo(state, action) {
      state.notificationInfo = action.payload;
    },
    /** @dispatch 알림 목록 설정 */
    setNotificationList(state, action) {
      state.notificationList = action.payload;
    },
    /** @dispatch 알림 목록 설정 */
    setNotificationPage(state, action) {
      state.notificationPage = action.payload;
    },
    /** @dispatch 알림 목록 추가 */
    pushNotificationList(state, action) {
      state.notificationList = [...state.notificationList, ...action.payload];
    },
    setRecentRecommendClassList(state, action) {
      state.recentClassList = action.payload;
    },
    /** @dispatch 관심 클래스 목록 설정 */
    setBookmarkClassList(state, action) {
      state.bookmarkClassList = action.payload;
    },
    /** @dispatch 관심 클래스 목록 추가 */
    pushBookmarkClassList(state, action) {
      state.bookmarkClassList = [...state.bookmarkClassList, ...action.payload];
    },
    /** @dispatch 팔로잉 유저 설정 */
    setFollow(state, action) {
      state.member.follow = action.payload;
    },
    /** @dispatch 팔로잉 유저 추가 */
    pushFollow(state, action) {
      state.member.follow = [...state.member.follow, ...action.payload];
    },
    /** @dispatch 팔로워 유저 설정 */
    setFollower(state, action) {
      state.member.follower = action.payload;
    },
    /** @dispatch 팔로워 유저 추가 */
    pushFollower(state, action) {
      state.member.follower = [...state.member.follower, ...action.payload];
    },
    /** @dispatch 차단 유저 설정 */
    setBlockedUser(state, action) {
      state.member.blocked = action.payload;
    },
    /** @dispatch 차단 유저 추가 */
    pushBlockedUser(state, action) {
      state.member.blocked = [...state.member.blocked, ...action.payload];
    },
  },
});

export const {
  updateInfo,
  setProfile,
  setEditProfile,
  pushProfileActivity,
  setSubInfo,
  setPointList,
  updateClassList,
  setNewsCount,
  setUserRecommendClassList,
  setRegisteredClassList,
  setNotificationInfo,
  setNotificationPage,
  setNotificationList,
  pushNotificationList,
  setRecentRecommendClassList,
  setBookmarkClassList,
  pushBookmarkClassList,
  setFollow,
  pushFollow,
  setFollower,
  pushFollower,
  setBlockedUser,
  pushBlockedUser,
} = userSlice.actions;

export default userSlice.reducer;
