import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUnixTimestamp } from 'lib/utils/format/time';
import { v4 as uuid } from 'uuid';

interface Query {
  utm_source: string;
  utm_media: string;
  utm_campaign: string;
  utm_content: string;
}

interface ImageData {
  class_id: number;
  class_name: string;
  coach_name: string;
  curriculum_cnt: number;
  review_count: number;
  review_score: number;
  signup_cnt: number;
}

interface ClickableInformation {
  [prop: string]: ImageData[];
}

export interface DataMining {
  uuid: string;
  sessionId: string;
  device: string;
  timeStamp: number;
  pageTitle: string;
  queryInfo: object | null;
  classInfo?: ClickableInformation;
  filterTypes?: SortByItem[];
  countRemainingTime?: string;
  clickedItems?: ClickedItem[];
  BROWSER_MODE: IsReadyToPost;
  famousClassKeyword?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  categoryName?: string;
  [prop: string]: any;
}

type IsReadyToPost = 'INITIAL' | 'READY' | 'NOT_INITIAL';

interface ClickedItem {
  innerText: string;
  elementType: string;
  timeStamp: number;
}

export interface SortByItem {
  sortText: string;
  timeStamp: number;
}

export const initialState: DataMining = {
  uuid: '',
  sessionId: uuid(),
  device: 'WEB',
  timeStamp: getUnixTimestamp(),
  pageTitle: '',
  queryInfo: null,
  classInfo: null,
  clickedItems: null,
  sortBy: null,
  countRemainingTime: null,
  BROWSER_MODE: 'INITIAL',
  famousClassKeyword: null,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_content: null,
};

export const dataMiningSlice = createSlice({
  name: 'dataMining',
  initialState,
  reducers: {
    setUuid: (state, action) => {
      state.uuid = action.payload;
    },
    setSessionID: state => {
      state.sessionID = uuid();
    },
    setPageTitle: (state, action: PayloadAction<string>) => {
      state.pageTitle = action.payload;
    },
    setQueryInfo: (state, action: PayloadAction<Query>) => {
      if (action.payload) {
        Object.entries(action.payload).forEach(([key, value]) => {
          state[key] = value;
        });
      }
    },
    setFamousClassKeyword: (state, action: PayloadAction<string>) => {
      state.famousClassKeyword = action.payload;
    },
    setClickableInformation: (state, action: PayloadAction<ClickableInformation>) => {
      if (state.BROWSER_MODE === 'INITIAL') {
        const values = Object.values(action.payload);
        if (values.length) {
          let isFullArray = true;
          for (const value of values) {
            if (!value.length) {
              isFullArray = false;
            }
          }
          if (isFullArray) {
            state.classInfo = action.payload;
            state.BROWSER_MODE = 'READY';
          }
        }
      }
      if (state.BROWSER_MODE === 'NOT_INITIAL') {
        state.classInfo = action.payload;
      }
    },
    setInitialMode: (state, action: PayloadAction<IsReadyToPost>) => {
      state.BROWSER_MODE = action.payload;
    },
    setCategoryName: (state, action) => {
      state.categoryName = action.payload;
    },
    findClickedData: (state, action: PayloadAction<string>) => {
      if (!state.clickedItems) {
        state.clickedItems = [];
      }
      Object.entries(state.classInfo).forEach(clickableData => {
        const [_, value] = clickableData;
        const findItem = value.find(item => item?.class_name === action.payload);
        if (findItem) {
          state.clickedItems.push({
            elementType: 'image',
            innerText: action.payload,
            timeStamp: getUnixTimestamp(),
          });
        }
      });
    },
    setClickedItems: (state, action: PayloadAction<{ innerText: string; elementType: string }>) => {
      if (!state.clickedItems) {
        state.clickedItems = [];
      }
      state.clickedItems.push({
        innerText: action.payload.innerText,
        elementType: action.payload.elementType,
        timeStamp: getUnixTimestamp(),
      });
    },

    setLectureTotalCount: (state, action: PayloadAction<number>) => {
      state.totalLectureCount = action.payload;
    },
    setSortByText: (state, action: PayloadAction<SortByItem[]>) => {
      state.sortBy = action.payload;
    },
    initializeSortItem: state => {
      state.sortBy = [];
    },
    setLeftTime: (state, action: PayloadAction<string>) => {
      state.countRemainingTime = action.payload;
    },
    initializeItems: state => {
      state.sortBy = null;
      state.queryInfo = null;
      state.timeStamp = getUnixTimestamp();
      state.pageTitle = '';
      state.queryInfo = null;
      state.classInfo = null;
      state.clickedItem = null;
      state.clickedItems = null;
      state.leftTime = null;
      state.famousClassKeyword = null;
      state.BROWSER_MODE = 'NOT_INITIAL';
      state.utm_source = null;
      state.utm_medium = null;
      state.utm_campaign = null;
      state.utm_content = null;
    },
  },
  extraReducers: {},
});

export const {
  setQueryInfo,
  setClickableInformation,
  findClickedData,
  setPageTitle,
  setClickedItems,
  setLectureTotalCount,
  setSortByText,
  setSessionID,
  setLeftTime,
  initializeItems,
  initializeSortItem,
  setInitialMode,
  setFamousClassKeyword,
  setCategoryName,
  setUuid,
} = dataMiningSlice.actions;

export default dataMiningSlice.reducer;
