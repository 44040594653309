import request from 'api';

/* =============================== GET ================================ */

/**
 * 피드 리스트 호출 API
 * @version 3
 * @param {object} props
 * @param {number} props.page 페이지
 */
export const getFeedList = ({ page = 1, section }) => {
  return request.get(`/enfit1/api/v3/squareWith_friend/${page}`, { params: { section } });
};

/**
 * 피드 상세페이지 호출 API
 * @version 3
 * @param {object} props
 * @param {number} props.page 페이지
 */
export const getFeedDetail = ({ commentId }) => {
  return request.get(`/enfit1/api/v3/squareDetail/${commentId}`);
};

/**
 * 피드 댓글 호출 API
 * @version 3
 * @param {object} props
 * @param {number} props.page 페이지
 */
export const getFeedReply = ({ commentId, page = 1 }) => {
  return request.get(`/enfit1/api/v3/squareDetail_comment/${commentId}/${page}`);
};

/* =============================== POST =============================== */

/**
 * 팔로우하기
 * @param {object} props
 * @param {string | number} props.userId 유저 ID
 */
export const postFollow = ({ userId }) => {
  return request.post(`/enfit1/api/v3/friend/${userId}`);
};

/**
 * 팔로우 취소하기
 * @param {object} props
 * @param {string | number} props.userId 유저 ID
 */
export const postUnfollow = ({ userId }) => {
  const data = new FormData();
  data.append('mode', 'delete');
  return request.post(`/enfit1/api/v3/friend/${userId}`, data);
};

/**
 * 피드 신고하기
 * @param {object} props
 * @param {string} props.commentId 피드 ID
 */
export const postDeclareFeed = ({ commentId }) => {
  return request.post(`/enfit1/api/v3/poorFeed/${commentId}`, { poor_flag: 1 });
};

/**
 * 피드 도움돼요, 도움안돼요
 * @version 3
 *
 * @param {object} props
 * @param {string} props.commentId 피드 ID
 * @param {'add' | 'delete'} props.type 도움돼요, 취소
 */
export const postFeedReaction = ({ commentId, type }) => {
  return request.post(`/enfit1/api/v3/squareLike`, { comment_id: commentId, type });
};

/**
 * 댓글달기
 * @version 3
 *
 * @param {object} props
 * @param {string} props.commentId 코멘트 ID
 * @param {FormData} props.formData
 * @param {string} props.formData.content 댓글
 * @param {binary} props.formData.file 첨부 파일
 * @param {string | number} props.formData.emoticon 이모티콘 넘버
 * @param {string} props.formData.mentionId 멘션 ID
 */
export const postFeedReply = ({ commentId, formData }) => {
  return request.post(`/enfit1/api/v3/squareComment/${commentId}`, formData);
};

/**
 * 피드 및 댓글 삭제
 * @version 3
 *
 * @param {object} props
 * @param {string} props.commentId 코멘트 ID
 */
export const deleteFeedItem = ({ commentId }) => {
  return request.post(`/enfit1/api/v3/squareComment/${commentId}`, { type: 'delete' });
};

/**
 * 피드 업로드
 * @version 3
 *
 * @param {object} props
 * @param {FormData} props.formData
 * @param {string} props.formData.reqeust_job 파일 제거 작업
 * @param {'class_notice' | ''} props.formData.write_mode 공지사항 여부
 * @param {string} props.formData.content 내용
 * @param {string} props.formData.tag 태그
 * @param {number} props.formData.emoticon 이모티콘 번호
 * @param {File} props.formData.file 사진
 * @param {string?} props.formData.photo_file1 사진1 URL 교체
 * @param {string?} props.formData.photo_file2 사진2 URL 교체
 * @param {string?} props.formData.photo_file3 사진3 URL 교체
 * @param {string?} props.formData.photo_file4 사진4 URL 교체
 * @param {string?} props.formData.photo_file5 사진5 URL 교체
 * @param {string?} props.formData.photo_file6 사진6 URL 교체
 * @param {string?} props.request_job 사진을 삭제하기 위한 String (예시: photo_delete1;photo_delete2;)
 */
export const uploadFeed = ({ formData }) => {
  return request.post(`/enfit1/api/v3/app_comment`, formData);
};

/**
 * 피드 수정
 * @version 3
 *
 * @param {object} props
 * @param {FormData} props.formData
 * @param {'edit' | undefined} props.formData.mode
 */
export const updateFeed = ({ formData }) => {
  return request.post(`/enfit1/api/v3/app_comment`, formData);
};

/* =============================== PUT ================================ */

/* ============================== DELETE ============================== */
