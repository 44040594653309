import request from 'api';

/* =============================== GET ================================ */

/**
 * 세컨드 추천 클래스 목록 호출 API
 * @version 1
 * @param {object} props
 * @param {number} props.userId 유저 ID
 * @param {number} props.classId 클래스 ID
 */
export const getSecondClassList = ({ userId, classId }) => {
  return request.get(`/enfit2/api/v1/user/recom/secondclass/${userId}/${classId}`);
};

/**
 * 추천 클래스 목록 호출 API
 * @version 3
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const getUserRecommendClassList = ({ userId }) => {
  return request.get(`/enfit1/api/v3/recom/${userId}`);
};

/**
 * 키워드 기반 추천 호출 API
 * @version 3
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const getKeywordClassList = ({ userId }) => {
  if (userId) {
    return request.get(`/enfit2/api/v3/recom/keyword-base/${userId}`);
  }
  return request.get(`/enfit2/api/v3/recom/keyword-base`);
};

/**
 * 메인화면 클래스 추천 호출 API
 * @version 3
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const getMainClassList = ({ userId }) => {
  if (userId) {
    return request.get(`/enfit2/api/v3/recom/main/${userId}`);
  }
  return request.get(`/enfit2/api/v3/recom/main`);
};

/**
 * 기획전 클래스 추천 호출 API
 * @version 3
 * @param {object} props
 * @param {number=} props.userId 유저 ID
 */
export const getEventClassList = ({ userId }) => {
  if (userId) {
    return request.get(`/enfit2/api/v3/recom/event-base/${userId}`);
  }
  return request.get(`/enfit2/api/v3/recom/event-base`);
};

/**
 * 카테고리 목록 호출 API
 * @version 1
 */
export const getCategoryList = () => {
  return request.get(`/enfit2/api/v1/category`);
};

/**
 * 하위 카테고리 목록 호출 API
 * @version 1
 * @param {object} props
 * @param {string} props.category
 */
export const getSubCategoryList = ({ category }) => {
  const params = { category };
  return request.get(`/enfit2/api/v1/category`, { params });
};

/**
 * 카테고리별 클래스 목록 반환 API
 * @version 1
 * @param {object} props
 * @param {string} props.category 카테고리
 * @param {string} props.sub_category 하위 카테고리
 * @param {import('types/slices/category').CategorySort} props.sort_item 정렬 기준
 * @param {import('types/slices/category').CategoryOrder} props.order 정렬 순서
 * @param {number} props.skip 시작 인덱스
 * @param {number} props.limit 마지막 인덱스
 */
export const getCategoryClassList = ({ category, sub_category, sort_item, order, skip, limit }) => {
  const params = {
    category,
    sub_category,
    sort_item,
    order,
    skip,
    limit,
  };
  return request.get(`/enfit2/api/v1/category/class`, { params });
};

/* =============================== POST =============================== */

/* =============================== PUT ================================ */

/* ============================== DELETE ============================== */
