import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial State
 * @type {import('types/slices/service').InitialState}
 */
const initialState = {
  coupon: {
    code_flag: '',
    total_point: 0,
    montn_expiration_point: 0,
    point: 0,
  },

  noticeList: [],
};

/* 
	Slice
*/
const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    /** @dispatch coupon 정보 초기화 */
    initCoupon(state) {
      state.coupon = {
        code_flag: '',
        total_point: 0,
        montn_expiration_point: 0,
        point: 0,
      };
    },
    /** @dispatch coupon 정보 업데이트 */
    updateCoupon(state, action) {
      state.coupon = {
        code_flag: action.payload.code_flag,
        total_point: action.payload.total_point,
        montn_expiration_point: action.payload.montn_expiration_point,
        point: action.payload.point,
      };
    },
    /** @dispatch 공지사항 추가 */
    pushNoticeList(state, action) {
      state.noticeList = [...state.noticeList, ...action.payload];
    },
  },
});

export const { initCoupon, updateCoupon, pushNoticeList } = serviceSlice.actions;

export default serviceSlice.reducer;
