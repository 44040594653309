import React from 'react';
import styled, { css } from 'styled-components';
// hooks
import useNavigationOnSlider from 'hooks/common/useNavigationOnSlider';
// styles
import { mediaQuery } from 'cds/styles';

/**
 * 가로 스크롤 슬라이더 (네비게이션 포함)
 * @param {object} param0
 * @param {object[]} list
 * @param {ReactElement} item
 * @param {ReactElement} ref
 * @example
 * import HorizontalScrollSlider, { Slide } from 'components/molecules/common/HorizontalScrollSlider';
 * const Items = list.map(v => (
 *    <Slide key={v.class_id} slidePerView={slidePerView} />
 * );
 */
const HorizontalScrollSlider = ({ list, item }, ref) => {
  const [Navigation] = useNavigationOnSlider({
    ref,
    list,
  });

  return (
    <Wrapper>
      <Inner>
        <List ref={ref}>
          {item}
          <Navigation />
        </List>
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Inner = styled.div`
  overflow: hidden;
`;

const List = styled.ul`
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 16px;
  margin-left: -12px;
  white-space: nowrap;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background-color: transparent;
  }

  ${mediaQuery.medium} {
    margin-left: -16px;
  }

  ${mediaQuery.large} {
    padding: 0;
  }
`;

const createCss = obj => {
  let styles = '';

  for (let i = 0, len = obj.length; i < len; i++) {
    if (obj[i].break === 'default') {
      styles += `width: calc(100% / ${obj[i].view});`;
      continue;
    }

    styles += `
			@media (min-width: ${obj[i].break}px) {
				width: calc(100% / ${obj[i].view});
			}
		`;
  }

  return css`
    ${styles}
  `;
};

export const Slide = styled.li`
  display: ${({ variant }) => (variant === 'banner' ? 'none' : 'inline-block')};
  width: 100%;
  padding-left: 12px;
  font-size: 0;
  transition: width 0.2s ease;
  vertical-align: top;

  ${({ slidePerView }) => createCss(slidePerView)};

  ${mediaQuery.medium} {
    padding-left: 16px;
  }

  ${mediaQuery.large} {
    display: inline-block;
  }
`;

export default React.forwardRef(HorizontalScrollSlider);
