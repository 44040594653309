import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial State
 * @type {import('types/slices/studio').InitialState}
 */
const initialState = {
  dashboard: {
    isLoaded: false,
    management_class: [],
    user_info: {},
  },

  selectedClass: {},

  feedback: {
    content_review_count: 0,
    no_reply_review_count: 0,
    review_count: 0,
    review_score: 0,
    suggestions_count: 0,

    suggestions: [],
  },
};

/**
 * Slice
 */
const studioSlice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    /** @dispatch 대시보드 초기화 */
    initDashboard(state) {
      state.dashboard = initialState.dashboard;
      state.selectedClass = initialState.selectedClass;
      state.feedback = initialState.feedback;
    },
    /** @dispatch 피드백 건의사항 초기화 */
    initFeedbackSuggestions(state) {
      state.feedback.suggestions = [];
    },
    /** @dispatch 대시보드 정보 갱신 */
    updateDashboard(state, action) {
      state.dashboard = {
        ...action.payload,
        isLoaded: true,
      };
    },
    /** @dispatch 현재 클래스 정보 갱신 */
    setSelectedClass(state, action) {
      state.selectedClass = action.payload;
    },
    /** @dispatch 피드백 요약 정보 갱신 */
    updateFeedbackSummary(state, action) {
      state.feedback = {
        ...state.feedback,
        ...action.payload,
      };
    },
    /** @dispatch 피드백(건의사항) 리스트 갱신 */
    setSuggestions(state, action) {
      state.feedback.suggestions = action.payload;
    },
    /** @dispatch 대표 클래스 리스트 갱신 */
    setPrimaryClass(state, action) {
      state.dashboard.management_class = action.payload;
    },
  },
});

export const {
  initDashboard,
  initFeedbackSuggestions,
  updateDashboard,
  setSelectedClass,
  updateFeedbackSummary,
  setSuggestions,
  setPrimaryClass,
} = studioSlice.actions;

export default studioSlice.reducer;
