import Script from 'next/script';

/**
 * Kakao SDK for chat
 */
const KakaoSDK = () => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://developers.kakao.com/sdk/js/kakao.min.js"
        onLoad={() => {
          window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_KEY);
        }}
      />
    </>
  );
};

export default KakaoSDK;
