import Script from 'next/script';

/**
 * Selper Tiktok
 */
const TiktokAdvertisement = () => (
  <Script strategy="afterInteractive" src="https://analytics.tiktok.com/i18n/pixel/identify.js" />
);

export default TiktokAdvertisement;
