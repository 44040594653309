import request from 'api';

/* =============================== GET ================================ */

/**
 * 메인 배너 서비스 정보 요약 API
 */
export const getServiceInfo = () => {
  return request.get(`/enfit2/class-summary`);
};

/**
 * 공지사항 API
 * @version 3
 *
 * @param {number} page
 */
export const getNotice = page => {
  const params = { page };
  return request.get(`/enfit1/api/v3/notice`, {
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_DEFAULT_TOKEN}`,
    },
    params,
  });
};

/* =============================== POST =============================== */

/**
 * 적립금 쿠폰 매칭
 * @version 3
 * @param {object} props
 * @param {string} props.couponNumber 쿠폰번호
 */
export const postCheckCoupon = ({ couponNumber }) => {
  return request.post(`/enfit1/api/v3/service_code_use/${couponNumber}`);
};

/* =============================== PUT ================================ */

/* ============================== DELETE ============================== */
