import styled from 'styled-components';
import { useState, useRef } from 'react';
import { useRouter } from 'next/router';
// components
import IconButton from 'cds/buttons/Icon';
import Icon from 'cds/icons';
// styles
import { palette } from 'cds/styles';
import * as typography from 'cds/typography';

/** 헤더 검색 */
const MobileSearchInput = ({ ...props }) => {
  const router = useRouter();
  const { keyword } = router.query;

  const [isShowErase, setIsShowErase] = useState(false);
  const ref = useRef(null);

  const onSearch = () => {
    if (!ref.current) {
      return;
    }

    let value = ref.current.value;
    value = value.replace(/#/g, '');
    router.push(`/search?keyword=${value}`);
  };

  const onKeyDown = e => {
    props.onKeyDown && props.onKeyDown(e);

    if (e.key === 'Enter') {
      onSearch();
      return;
    }
  };

  const onChange = e => {
    props.onChange && props.onChange(e);

    ref.current.value = e.currentTarget.value;

    if (e.currentTarget.value.trim().length > 0) {
      setIsShowErase(true);
    } else {
      setIsShowErase(false);
    }
  };

  const onClear = () => (ref.current.value = '');

  return (
    <Layout>
      <Input
        type="text"
        placeholder="무엇을 배우고 싶나요?"
        ref={ref}
        {...props}
        onKeyDown={onKeyDown}
        onChange={onChange}
        defaultValue={keyword}
      />
      <IconButton onClick={onSearch}>
        <Icon name="ic_search" width={24} height={24} />
      </IconButton>

      {isShowErase && (
        <EraseButton width={32} height={22} onClick={onClear}>
          <Icon name="ic_close_circle_m" width={24} height={24} fill={palette.font.tertiary2} />
        </EraseButton>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;
  height: 44px;
  padding: 12px;
  border: 1px solid ${palette.grey020};
  border-radius: 8px;
  background-color: ${palette.background.bg2};
`;

const Input = styled.input`
  ${typography.subtitle4_medium};
  flex: 1;
  color: ${palette.font.tertiary1};
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);

  &::placeholder {
    color: ${palette.font.tertiary2};
  }
`;

const EraseButton = styled(IconButton)`
  position: absolute;
  right: 48px;
  top: 10px;

  &:active {
    & svg {
      transition: fill 0.1s ease;
      fill: ${palette.grey060};
    }
  }
`;

export default MobileSearchInput;
