import { useEffect } from 'react';
// slices
import { getNotificationList, getNewsCount } from 'slices/user.thunk';
// hooks
import { useDispatch, useSelector } from 'hooks/common/useStore';

/**
 * 알림 호출 Hook
 */
const useNotification = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector(state => state.user.info);

  useEffect(() => {
    if (!userInfo.isLoaded && !userInfo.id) {
      return;
    }

    dispatch(getNotificationList({ page: 1 })).catch(() => {});
    dispatch(getNewsCount()).catch(() => {});
  }, [dispatch, userInfo.id, userInfo.isLoaded]);

  return {};
};

export default useNotification;
