import { useRef, useCallback } from 'react';

/**
 * useThrottle 훅
 *
 * @example
 * const throttle = useThrottle();
 *
 * const onChange = throttle.run((e) => { ... }, 600);
 */
const useThrottle = () => {
  const waiting = useRef(false);
  const timer = useRef(null);

  const run = useCallback(function (fn, limit = 100) {
    return function () {
      const context = this;
      const args = arguments;

      clearTimeout(timer.current);
      timer.current = window.setTimeout(function () {
        fn.apply(context, args);
      }, limit);
      if (!waiting.current) {
        fn.apply(context, args);
        waiting.current = true;
        window.setTimeout(() => {
          waiting.current = false;
        }, limit);
      }
    };
  }, []);

  const cleanUp = useCallback(() => {
    window.clearTimeout(timer.current);
  }, []);

  return {
    run,
    cleanUp,
  };
};

export default useThrottle;
