import { useRouter } from 'next/router';
// hooks
import useBridgeCallback from 'hooks/bridge/useBridgeCallback';
// utils
import deviceChecker from 'lib/utils/deviceChecker';

/**
 * 공통 bridge hook
 * @param {object} funcs
 * @example
 * const Component = () => {
 *   const { closeWebView } = useCommonBridge({ funcion1, funcion2 });
 * }
 */
const useCommonBridge = funcs => {
  useBridgeCallback(funcs);

  const router = useRouter();
  const device = deviceChecker();

  /** 유저 정보 업데이트 */
  const updateProfile = ({ nickname, photoUrl }) => {
    if (device.iosApp()) {
      return false;
    }

    if (device.androidApp()) {
      window.android.updateProfile(nickname, photoUrl);
      return true;
    }

    return false;
  };

  /** Webview 닫기 & Refresh */
  const onCloseWebView = () => {
    if (device.iosApp()) {
      window.webkit.messageHandlers.webViewExit.postMessage('exit');
      return true;
    }

    if (device.androidApp()) {
      window.android.exit();
      return true;
    }

    return false;
  };

  /** Webview 닫기 */
  const onExitWebView = () => {
    if (device.iosApp()) {
      window.webkit.messageHandlers.changeGroupHandler.postMessage('close');
      return true;
    }

    if (device.androidApp()) {
      // exit의 경우 페이지가 쌓이면 뒤로가기로 작동
      window.android.exit();
      return true;
    }

    return false;
  };

  /** Webview 전체 닫기 */
  const onForceCloseWebView = () => {
    if (device.iosApp()) {
      window.webkit.messageHandlers.changeGroupHandler.postMessage('close');
      return true;
    }

    if (device.androidApp()) {
      window.android.webViewExit();
      return true;
    }

    return false;
  };

  /** Webview 뒤로가기 & 닫기 */
  const onBackWebView = () => {
    if (device.iosApp()) {
      if (window.history.length > 1) {
        router.back();
        return true;
      }

      window.webkit.messageHandlers.changeGroupHandler.postMessage('close');
      return true;
    }

    if (device.androidApp()) {
      if (window.history.length > 1) {
        router.back();
        return true;
      }

      window.android.exit();
      return true;
    }

    return false;
  };

  /**
   * 외부 브라우저 열기
   * @param {object} props
   * @param {string} props.url
   */
  const onOutLinkForBrowser = ({ url }) => {
    if (device.iosApp()) {
      try {
        window.webkit.messageHandlers.outLinkForBrowser.postMessage(url);
      } catch (error) {
        console.error(error);
      }
      return true;
    }

    if (device.androidApp()) {
      try {
        window.android.outLinkForBrowser(url);
      } catch (error) {
        console.log(error);
      }

      return true;
    }

    return false;
  };

  /**
   * 사용자 프로필로 이동
   * @param {string | number} userId 사용자 ID
   */
  const goToProfile = userId => {
    userId = parseInt(userId, 10);

    if (device.iosApp()) {
      window.webkit.messageHandlers.goToProfile.postMessage(userId);
      return true;
    }

    if (device.androidApp()) {
      window.android.goToProfile(userId);
      return true;
    }

    window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/new/user/${userId}`;
    return false;
  };

  /**
   * 상품 페이지로 이동
   * @param {string | number} classId 클래스 ID
   */
  const goToClassDetail = classId => {
    classId = parseInt(classId, 10);

    if (device.iosApp()) {
      window.webkit.messageHandlers.goToDetailClass.postMessage(classId);
      return true;
    }

    if (device.androidApp()) {
      window.android.goToDetailClass(`${classId}`);
      return true;
    }

    window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/lecture/${classId}`;
    return false;
  };

  /** Webview 닫기 및 클래스탭으로 이동 */
  const goToClassTab = () => {
    if (device.iosApp() && device.overVersion('210')) {
      window.webkit.messageHandlers.goToClassTab.postMessage('goToClassTab');
      return true;
    }

    if (device.androidApp() && device.overVersion('210')) {
      window.android.goToClassTab();
      return true;
    }

    return false;
  };

  /** 앱스토어 리뷰 작성하러 가기 */
  const goToAppReview = () => {
    if (device.iosApp()) {
      window.webkit.messageHandlers.goToStoreReview.postMessage('review');
      return true;
    }

    if (device.androidApp()) {
      window.android.goToStoreReview('review');
      return true;
    }

    return false;
  };

  /**
   * iOS 좌우 Swiping 막기
   * - iOS Only
   */
  const preventSwiping = () => {
    if (device.iosApp() && device.overVersion('210')) {
      window.webkit.messageHandlers.changeGroupHandler.postMessage('preventSwiping');
      return true;
    }

    return false;
  };

  /**
   * 피드 상세페이지 이동
   */
  const goToFeedDetail = ({ commentTypeId }) => {
    if (device.iosApp()) {
      window.webkit.messageHandlers.goToFeedDetail.postMessage(commentTypeId);
      return true;
    }

    if (device.androidApp()) {
      window.android.goToFeedDetail(commentTypeId);
      return true;
    }

    router.push(`/community/${commentTypeId}`);

    return false;
  };

  /**
   * 리뷰 피드 상세페이지 이동
   */
  const goToReviewDetail = ({ commentTypeId }) => {
    const isTest = process.env.NEXT_PUBLIC_TEST === 'true';
    const host = isTest ? process.env.NEXT_PUBLIC_TEST_HOST : process.env.NEXT_PUBLIC_DEFAULT_HOST;

    const commentId = commentTypeId.replace(/[A-Za-z]/g, '');
    const viewURL = `${host}/new/community/${commentTypeId}`;
    const shareURL = `${host}/new/community/${commentTypeId}`;
    const editURL = `${host}/new/review/${commentId}/edit`;

    if (device.iosApp() && device.overVersion('220')) {
      window.webkit.messageHandlers.goToReviewFeedDetail.postMessage({
        commentId,
        viewURL,
        shareURL,
        editURL,
      });
      return true;
    }

    if (device.androidApp() && device.overVersion('220')) {
      window.android.goToReviewFeedDetail(commentId, shareURL, editURL);
      return true;
    }

    router.push(`/community/${commentTypeId}`);

    return false;
  };

  /**
   * 커뮤니티 수정 페이지 이동
   */
  const goToFeedEdit = ({ commentTypeId }) => {
    if (device.iosApp() && device.overVersion('220')) {
      window.webkit.messageHandlers.goToFeedEdit.postMessage(commentTypeId);
      return true;
    }

    if (device.androidApp() && device.overVersion('220')) {
      window.android.goToFeedEdit(commentTypeId);
      return true;
    }

    router.push(`/community/${commentTypeId}/edit`);

    return false;
  };

  /**
   * 커뮤니티 리뷰 수정 페이지 이동
   */
  const goToReviewFeedEdit = ({ reviewId }) => {
    const isTest = process.env.NEXT_PUBLIC_TEST === 'true';
    const host = isTest ? process.env.NEXT_PUBLIC_TEST_HOST : process.env.NEXT_PUBLIC_DEFAULT_HOST;
    const URL = `${host}/new/review/${reviewId}/edit`;

    if (device.iosApp() && device.overVersion('220')) {
      window.webkit.messageHandlers.goToReviewFeedEdit.postMessage(URL);
      return true;
    }

    if (device.androidApp() && device.overVersion('220')) {
      window.android.goToReviewFeedEdit(URL);
      return true;
    }

    router.push(`/review/${reviewId}/edit`);

    return false;
  };

  /**
   * 피드 댓글페이지 이동
   */
  const goToFeedReply = ({ commentTypeId }) => {
    if (device.iosApp()) {
      window.webkit.messageHandlers.goToFeedReply.postMessage(commentTypeId);
      return true;
    }

    if (device.androidApp()) {
      window.android.goToFeedReply(commentTypeId);
      return true;
    }

    router.push(`/community/${commentTypeId}`);

    return false;
  };

  /**
   * 탐색탭 페이지 이동
   */
  const goToSearch = () => {
    if (device.iosApp()) {
      window.webkit.messageHandlers.goToSearch.postMessage('');
      return true;
    }

    if (device.androidApp()) {
      window.android.goToSearch('');
      return true;
    }

    return false;
  };

  /**
   * 채팅방으로 이동
   */
  const goToChat = ({ userId }) => {
    if (device.iosApp()) {
      window.webkit.messageHandlers.goToChatDetail.postMessage(userId);
      return true;
    }

    if (device.androidApp()) {
      window.android.goToChatDetail(userId);
      return true;
    }

    window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/chat/${userId}`;

    return false;
  };

  /**
   * 사용자 신고하기
   */
  const onReportUser = ({ userId }) => {
    if (device.iosApp() && device.overVersion('220')) {
      const commentId = 'string';
      const viewURL = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/new/user/report/${userId}`;
      const shareURL = 'string';
      const editURL = 'string';

      window.webkit.messageHandlers.goToReviewFeedDetail.postMessage({
        commentId,
        viewURL,
        shareURL,
        editURL,
      });
      return true;
    }

    if (device.androidApp()) {
      window.android.onReportUser(userId);
      return true;
    }

    router.push(`/user/report/${userId}`);
    return false;
  };

  const onWithdrawMembership = () => {
    try {
      if (device.iosApp()) {
        window.webkit.messageHandlers.goToReviewFeedDetail.postMessage('withdrawMembership');
        return true;
      }

      if (device.androidApp()) {
        window.android.onWithdrawMembership();
        return true;
      }
    } catch (error) {
      console.error('withdrawMembership not found.');
    }

    window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/modoo/logout`;
    return false;
  };

  return {
    updateProfile,
    onCloseWebView,
    onBackWebView,
    onExitWebView,
    onForceCloseWebView,
    onOutLinkForBrowser,
    goToProfile,
    goToClassDetail,
    goToClassTab,
    goToFeedDetail,
    goToReviewDetail,
    goToFeedEdit,
    goToReviewFeedEdit,
    goToFeedReply,
    goToAppReview,
    preventSwiping,
    goToSearch,
    goToChat,
    onReportUser,
    onWithdrawMembership,
  };
};

export default useCommonBridge;
