import { useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import SHA256 from 'lib/utils/server/sha256';
// hooks
import { useSelector } from 'hooks/common/useStore';

/**
 * Selper 기본값
 */

const Snippets = {
  script: ({ email, id, phone }: { email: string; id: string; phone: string }) => {
    const code = `
      var __selperUserEm = '${email}';
      var __selperUserId = '${id}';
      var __selperUserPh = '${phone}';
      var __selperUserGe = '';
    `;

    return code;
  },
};

const Advertisement = () => {
  const userInfo = useSelector(state => state.user.info);

  useEffect(() => {
    if (!userInfo.isLoaded) {
      return;
    }

    const script = document.createElement('script');
    const email = userInfo.email
      ? window.encodeURI(SHA256(userInfo.email.toLocaleLowerCase()))
      : '';
    const id = userInfo.id ? window.encodeURI(SHA256(String(userInfo.id))) : '';
    const phone = userInfo.phone ? window.encodeURI(userInfo.phone) : '';
    const code = Snippets.script({ email, id, phone });

    script.innerHTML = code;
    document.head.insertBefore(script, document.head.childNodes[0]);
  }, [userInfo]);

  return null;
};

export default Advertisement;
