import { createAsyncThunk } from '@reduxjs/toolkit';
import { postDataMiningDataAPI } from 'api/dataMining';
import {
  removeLocalData,
  removeSessionData,
  setDataToLocalStorage,
  setDataToSessionStorage,
  setDataToSessionStorageWithObject,
} from 'lib/utils/storageItem';
// actions
import * as actions from 'slices/dataMining';

/**
 * @dispatch 데이터 마이닝 적재 Thunk
 */
export const postDataMiningDataUnload = createAsyncThunk(
  'dataMining/postDataMiningDataUnload',
  async (_, { dispatch, getState }) => {
    const dataState = getState() as any;
    let data = dataState.dataMining as actions.DataMining;

    // Nullable 값 삭제
    data = Object.keys(data)
      .filter(k => data[k] !== null)
      .filter(k => typeof data[k] !== 'boolean')
      .reduce((a, k) => ({ ...a, [k]: data[k] }), {}) as actions.DataMining;
    const filterTypes = sessionStorage.getItem('filterTypes');
    if (filterTypes) {
      const parsedData = JSON.parse(filterTypes) as actions.SortByItem[];
      data = { ...data, filterTypes: parsedData };
    }

    delete data['classInfo'];
    delete data['clickedItems'];

    const { uuid, sessionId, utm_campaign, utm_content, utm_medium, utm_source } = data;
    const analysisData = { utm_campaign, utm_content, utm_medium, utm_source };

    // uuid, sessionId 저장
    setDataToLocalStorage('classu_uuid', uuid);
    setDataToSessionStorage('sessionId', sessionId);
    setDataToSessionStorageWithObject(analysisData);

    // API Calling
    postDataMiningDataAPI(data);

    // 초기화
    dispatch(actions.initializeItems());
    removeSessionData(['filterTypes']);
    return Promise.resolve(true);
  },
);

export const postDataMiningDataLoad = createAsyncThunk(
  'dataMining/postDataMiningDataLoad',
  async (_, { getState, dispatch }) => {
    const dataState = getState() as any;

    let data = dataState.dataMining as actions.DataMining;

    const { uuid, sessionId, utm_campaign, utm_content, utm_medium, utm_source } = data;
    const analysisData = { utm_campaign, utm_content, utm_medium, utm_source };

    // Nullable 값 삭제
    data = Object.keys(data)
      .filter(k => data[k] !== null)
      .filter(k => typeof data[k] !== 'boolean')
      .reduce((a, k) => ({ ...a, [k]: data[k] }), {}) as actions.DataMining;

    delete data['classInfo'];
    delete data['clickedItems'];

    dispatch(actions.setInitialMode('NOT_INITIAL'));
    // uuid, sessionId 저장
    removeLocalData('uuid');
    setDataToLocalStorage('classu_uuid', uuid);
    setDataToSessionStorage('sessionId', sessionId);
    setDataToSessionStorageWithObject(analysisData);

    // API Calling
    postDataMiningDataAPI(data);

    // 초기화

    return Promise.resolve(true);
  },
);
