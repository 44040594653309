/**
 * Site Verification
 * - Google
 * - Naver
 */
const SiteVerification = () => (
  <>
    {/* selper */}
    <meta name="google-site-verification" content="Vy_loMLJ7b0xNEkv-1FWPTXwWR_WMcc8SZaoxXo01GU" />
    <meta name="google-site-verification" content="HtJ9NN3S9NbZ7BJGMa17jczPA-Vde96E0cOozpchJag" />
    <meta name="google-site-verification" content="bLmTyzEezTjR3Tj_5XieuRVdA27XpOuP9rdPKRRGRmg" />
    <meta name="google-site-verification" content="hB5ETZDTrr1wUshaf7EC6U6vuQpu58AnpPN1cw8K1MI" />
    <meta name="naver-site-verification" content="d1e2d72825bac3bfd32fc15f8712824fe92074b5" />
  </>
);

export default SiteVerification;
