import { createSlice } from '@reduxjs/toolkit';

/**
 * InitialState
 * @type {import('types/slices/category').InitialState}
 */
const initialState = {
  info: {
    totalCount: 0,
    category: '',
    subCategory: '',
  },

  categoryList: [],

  subCategoryList: {},

  recommendClassList: [],

  classList: [],
};

/*
	Slice
*/
const CategorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    /** @dispatch 카테고리 정보 갱신 */
    updateInfo(state, action) {
      state.info = { ...state.info, ...action.payload };
    },
    setCategoryList(state, action) {
      /** @dispatch 카테고리 목록 갱신 */
      state.categoryList = action.payload;
    },
    /** @dispatch 하위 카테고리 목록 갱신 */
    updateSubCategoryList(state, action) {
      state.subCategoryList = { ...state.subCategoryList, ...action.payload };
    },
    /** @dispatch 추천 클래스 리스트 정보 갱신 */
    setRecommendClassList(state, action) {
      state.recommendClassList = action.payload;
    },
    /** @dispatch 필터링 클래스 리스트 정보 갱신 */
    setClassList(state, action) {
      state.classList = action.payload;
    },
    /** @dispatch 필터링 클래스 리스트 추가 */
    updateClassList(state, action) {
      state.classList = [...state.classList, ...action.payload];
    },
  },
});

export const {
  updateInfo,
  setCategoryList,
  updateSubCategoryList,
  setRecommendClassList,
  setClassList,
  updateClassList,
} = CategorySlice.actions;

export default CategorySlice.reducer;
