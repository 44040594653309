import axios from 'axios';
import type { DataMining } from 'slices/dataMining';

const postDataMiningDataAPI = async (data: DataMining) => {
  return axios.post('/new/classu/web', JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });
};

export { postDataMiningDataAPI };
