import { useEffect } from 'react';

/**
 * Webview bridge callback hook
 * - Native App -> Web
 * @param {object} funcs
 * @example
 * const Component = () => {
 *   useBridgeCallback({ function1, function2 });
 * }
 */
const useBridgeCallback = funcs => {
  /** Bridge 콜백 함수 적용 */
  useEffect(() => {
    if (!funcs) {
      return;
    }
    Object.keys(funcs).map(func => (window[func] = funcs[func]));
    return () => Object.keys(funcs).map(func => (window[func] = null));
  }, [funcs]);
};

export default useBridgeCallback;
