import { useRef, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
// hooks
import { useSelector } from 'hooks/common/useStore';
// utills
import { isExistWindow } from 'lib/utils/helper';

/**
 * Laravel 과 Node에서 로그인 불일치 관리 Hook
 */
const useCheckLogin = () => {
  const router = useRouter();
  const userInfo = useSelector(state => state.user.info);
  const isMount = useRef(false);

  const makeMyPageOnIframe = useCallback(() => {
    if (!userInfo.isLoaded) {
      return;
    }

    if (isMount.current) {
      return;
    }

    const iframe = document.createElement('iframe');
    iframe.src = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/modoo/checkLogin`;
    iframe.id = 'clsu';
    iframe.width = '0px';
    iframe.height = '0px';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    iframe.addEventListener('load', function () {
      const iframeDOM = document.getElementById('clsu');
      if (!iframeDOM) {
        return;
      }

      const innerDoc = iframeDOM.contentDocument || iframeDOM.contentWindow.document;
      if (!innerDoc) {
        return;
      }

      const input = innerDoc.getElementById('__garnet');
      if (!input) {
        return;
      }

      const laravelUserId = input.value;
      if (!laravelUserId) {
        return;
      }

      if (String(userInfo.id) === String(laravelUserId)) {
        return;
      }

      if (String(userInfo.id) !== String(laravelUserId)) {
        iframe.src = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/modoo/logout`;
      }

      window.parent.location.reload();
    });

    document.body.appendChild(iframe);

    isMount.current = true;
  }, [userInfo.id, userInfo.isLoaded]);

  useEffect(() => {
    if (!userInfo.isLoaded) {
      return;
    }

    if (!isExistWindow) {
      return;
    }

    const host = window.location.hostname;
    if (host === 'localhost') {
      return;
    }

    if (process.env.NEXT_PUBLIC_DEFAULT_HOST.indexOf(host) === -1) {
      return;
    }

    makeMyPageOnIframe();
  }, [router, makeMyPageOnIframe, userInfo.id, userInfo.isLoaded]);
};

export default useCheckLogin;
