import { createAsyncThunk } from '@reduxjs/toolkit';
// actions
import * as actions from 'slices/category';
// api
import * as recommendAPI from 'api/recommend';

/**
 * @dispatch 카테고리 리스트 호출
 */
export const getCategoryList = createAsyncThunk(
  'category/getCategoryList',
  async (_, { dispatch }) => {
    const res = await recommendAPI.getCategoryList();

    dispatch(actions.setCategoryList(res));

    return Promise.resolve(true);
  },
);

/**
 * @dispatch 하위 카테고리 리스트 호출
 * @param {object} props
 * @param {string} props.category 카테고리
 */
export const getSubCategoryList = createAsyncThunk(
  'category/getSubCategoryList',
  async ({ category }, { dispatch }) => {
    const res = await recommendAPI.getSubCategoryList({ category });

    dispatch(actions.updateSubCategoryList({ [category]: res }));

    return Promise.resolve(true);
  },
);

/**
 * @dispatch 카테고리별 클래스 목록 호출
 * @param {object} props
 * @param {string} props.category 카테고리
 * @param {string} props.sub_category 하위 카테고리
 * @param {import('types/slices/category').CategorySort} props.sort_item 정렬 기준
 * @param {import('types/slices/category').CategoryOrder} props.order 정렬 순서
 * @param {number} props.skip 시작 인덱스
 * @param {number} props.limit 마지막 인덱스
 */
export const getCategoryClassList = createAsyncThunk(
  'category/getCategoryClassList',
  async ({ category, subCategory, sort, order, skip, limit }, { dispatch }) => {
    const res = await recommendAPI.getCategoryClassList({
      category,
      sub_category: subCategory,
      sort_item: sort,
      order,
      skip,
      limit,
    });
    dispatch(actions.updateInfo({ totalCount: res['total_count'] }));
    dispatch(actions.updateClassList(res['class_list']));

    return Promise.resolve(res['class_list']);
  },
);
