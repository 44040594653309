import { createSlice } from '@reduxjs/toolkit';

/**
 * InitialState
 * @type {import('types/slices/search').InitialState}
 */
const initialState = {
  info: {
    totalCount: 0,
  },

  recentKeywordList: [],

  classList: [],

  autoCompletedKeywordList: [],

  keywordRankList: [],
};

/*
	Slice
*/
const SearchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    /** @dispatch 카테고리 정보 갱신 */
    updateInfo(state, action) {
      state.info = { ...state.info, ...action.payload };
    },
    /** @dispatch 최근 검색어 목록 갱신 */
    setRecentKeywordList(state, action) {
      state.recentKeywordList = action.payload;
    },
    /** @dispatch 카테고리 목록 갱신 */
    setCategoryList(state, action) {
      state.categoryList = action.payload;
    },
    /** @dispatch 검색 자동완성 키워드 목록 갱신 */
    setAutoCompletedKeywordList(state, action) {
      state.autoCompletedKeywordList = action.payload;
    },
    /** @dispatch 검색 결과 클래스 리스트 설정 */
    setClassList(state, action) {
      state.classList = action.payload;
    },
    /** @dispatch 검색 결과 클래스 리스트 추가 */
    updateClassList(state, action) {
      state.classList = [...state.classList, ...action.payload];
    },
    /** @dispatch 검색 키워드 랭킹 리스트 추가 */
    setKeywordRankList(state, action) {
      state.keywordRankList = [...state.keywordRankList, ...action.payload];
    },
  },
});

export const {
  updateInfo,
  setRecentKeywordList,
  setCategoryList,
  setAutoCompletedKeywordList,
  setClassList,
  updateClassList,
  setKeywordRankList,
} = SearchSlice.actions;

export default SearchSlice.reducer;
