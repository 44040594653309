import { useEffect } from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
// api
import { getToken } from 'api/user';
// slices
import { updateInfo } from 'slices/user';
import { getUserData } from 'slices/user.thunk';
// hooks
import { useDispatch } from 'hooks/common/useStore';

/**
 * 사용자 token 설정 hook
 * - PHP Server -> Node Server 이동 유저를 위한 인증 정보 설정
 */
const useAuthorization = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  /** 토큰 설정 */
  const setToken = token => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  /** 해시값 설정 */
  const setHash = hash => {
    axios.defaults.hash = hash;
  };

  /**
   * 토큰 문제가 있는 사용자의 경우 로그아웃 페이지로 이동합니다.
   * 쿠키 삭제 처리가 이루어지며, 로그인 페이지로 이동합니다.
   */
  const setLogout = () => {
    if (typeof window === 'undefined') {
      return;
    }

    window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/modoo/logout`;
  };

  useEffect(() => {
    /* 개발 모드에서의 인증 절차 */
    if (process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_TOKEN) {
      setToken(process.env.NEXT_PUBLIC_TOKEN);

      (async () => {
        const res = await dispatch(getUserData());
        if (res.payload === 470) {
          setLogout();
        }
      })();

      return;
    }

    /* cartalyst_sentinel를 사용한 인증 절차 */
    getToken()
      .then(async res => {
        const token = res.token;
        if (!token) {
          return;
        }

        setToken(token);

        (async () => {
          const res = await dispatch(getUserData());
          if (res.payload === 470) {
            setLogout();
          }
        })();
      })
      .catch(() =>
        dispatch(
          updateInfo({
            isLoaded: true,
          }),
        ),
      );
  }, [dispatch]);

  useEffect(() => {
    /* URL Query에 token이 존재할 때 */
    if (router.query.token) {
      setToken(router.query.token);

      (async () => {
        const res = await dispatch(getUserData());
        if (res.payload === 470) {
          setLogout();
        }
      })();

      return;
    }

    /* URL Query에 hash가 존재할 때 */
    if (router.query.hash) {
      setHash(router.query.hash);

      (async () => {
        const res = await dispatch(getUserData());
        if (res.payload === 470) {
          setLogout();
        }
      })();

      return;
    }
  }, [dispatch, router.query.token, router.query.hash]);

  return {};
};

export default useAuthorization;
