/**
 * URL Parameters 파서
 * @param {string} url URL
 * @returns {object}
 */
export const getURLParams = url => {
  let result = {};
  url.replace(/[?&]{1}([^=&#]+)=([^&#]*)/g, function (s, k, v) {
    result[k] = decodeURIComponent(v);
  });
  return result;
};
