/**
 * 한국 기준 현재 시간 가져오기
 */
export const getKoreaDate = () => {
  const now = new Date();
  const utcNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
  const koreaTimeDiff = 9 * 60 * 60 * 1000;
  const koreaNow = new Date(utcNow + koreaTimeDiff);
  return koreaNow;
};

/**
 * 문자열 시간 Formatter
 *
 * 현재 시간이 "2021-07-06 13:00:00" 이라는 가정
 * - "2020-02-20" -> "2020.02.20"
 * - "2021-07-03" -> "2021.07.03"
 * - "2021-07-06 07:30:00" -> "5시간 전"
 * - "2021-07-06 12:30:00" -> "30분 전"
 * - "2021-07-06 12:59:30" -> "방금 전"
 *
 * @param {string} value 문자열 시간
 * @returns {string} 포매팅 된 시간
 */
export const stringTimeFormatter = value => {
  if (!value) {
    return '방금 전';
  }

  const [date_, time_] = value.split(' ');
  const [year, month, day] = date_.split('-').map(v => parseInt(v, 10));
  const date = new Date(date_ + (time_ ? `T${time_}` : ''));
  const curDate = getKoreaDate();
  const time = date.getTime();
  const [curTime, curYear, curMonth, curDay] = [
    curDate.getTime(),
    curDate.getFullYear(),
    curDate.getMonth() + 1,
    curDate.getDate(),
  ];

  if (year < curYear) {
    return date_.replace(/-/g, '.');
  } else if (month < curMonth) {
    return date_.replace(/-/g, '.');
  } else if (day < curDay) {
    return date_.replace(/-/g, '.');
  } else if (day === curDay && !time_) {
    return date_.replace(/-/g, '.');
  }

  const diffMinute = Math.floor((curTime - time) / 1000 / 60);
  if (diffMinute < 1) {
    return '방금 전';
  }
  if (diffMinute < 60) {
    return `${diffMinute}분 전`;
  }

  const diffHour = Math.floor(diffMinute / 60);
  if (diffHour < 24) {
    return `${diffHour}시간 전`;
  }

  const diffDays = Math.floor(diffMinute / 60 / 24);
  if (diffDays <= 7) {
    return `${diffDays}일 전`;
  }

  if (diffDays <= 28) {
    return `${Math.floor(diffDays / 7)}주 전`;
  }

  return date_.replace(/-/g, '.');
};

/**
 * 문자열시간 Formater
 *
 * '-' 표시를 '.' 표시로 변경한다.
 * - 2021-07-08 11:22:33 -> 2021.07.08
 * - 2021-07-08 -> 2021.07.08
 *
 * @param {string} time 문자열 시간
 * @returns {string} 포매팅 된 시간
 */
export const dottedTimeFormatter = time => {
  time = time.replace(/-/g, '.');
  if (/ /g.test(time)) {
    return time.slice(0, -9);
  } else {
    return time;
  }
};

/**
 * 문자열시간 한글 Formatter
 *
 * - 2021-07-08 -> 2021년 07월 08일
 * - 2021-07-08 -> 2021년 07월
 * - 2021-07-08 -> 07월 08일
 * - 2021-07-08 -> 2021년
 * - 2021-07-08 -> 07월
 * - 2021-07-08 -> 08일
 *
 * @param {string} time 문자열 시간
 * @param {object} config
 * @param {boolean} config.year
 * @param {boolean} config.month
 * @param {boolean} config.day
 * @returns {string} 포매팅 된 시간
 */
export const koreanTimeFormatter = (time, { year, month, day }) => {
  if (!time) {
    return '';
  }

  let value = '';
  const [year_, month_, day_] = time.split('-');

  if (year) {
    value += `${year_}년`;
  }
  if (month) {
    value += ` ${month_}월`;
  }
  if (day) {
    value += ` ${day_}일`;
  }

  return value;
};

/**
 * 문자열시간 데이트타임 Formmater
 *
 * - 2021-07-08 -> DateTime
 *
 * @param {string} time
 * @returns {Date} 포매팅 된 시간
 */
export const dateTimeFormatter = time => new Date(time);

/** iOS 시간 포맷 반환 */
export const iosTimeFormat = date => {
  if (!date) {
    return getKoreaDate();
  }

  let arr = date.split(/[- :]/);
  return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
};

export const getUnixTimestamp = () => new Date().getTime() / 1000;
