import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial State
 * @type {import('types/slices/feed').InitialState}
 */
const initialState = {
  main: {
    list: [],
    page: 1,
    total_page: 1,
  },

  selected: {
    user_id: 0,
    comment_id: '',
    user_photo: '',
    user_name: '',
    friend_status: 'N',
    created_at: '',
    fixed_interest1: 'string',
    fixed_interest2: 'string',
    content: '',
    class_name: '',
    like_status: 'N',
    like_cnt: 0,
    like_yn: 'N',
    reply_cnt: 0,
    user_info: {
      active_list: [],
      friend_status: 'N',
      gender: '',
      mcFriend_cnt: 0,
      user_id: 0,
      user_name: '',
      user_photo: '',
    },
    mcClass_id: 0,
    block_flag: 'N',
    blocked: false,
    youtube_file: '',
    photo: [],
    photo_list: [], // for review
    class_photo: '',
    class_signup_data: 0,
  },

  reply: {
    list: [],
    page: '1',
    total_page: 1,
  },
};

/*
	Slice
*/
const FeedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    /** @dispatch 피드 리스트 초기화 */
    initFeedList(state) {
      state.main = initialState.main;
    },
    /** @dispatch 피드 리스트 갱신 */
    updateFeedList(state, action) {
      state.main = {
        ...state.main,
        ...action.payload,
        list: [...state.main.list, ...action.payload.list],
      };
    },
    /** @dispatch 피드 상세 정보 - 도움돼요, 도움안돼요 갱신 */
    setFeedList(state, action) {
      state.main.list = action.payload;
    },
    /** @dispatch 피드 단일 정보 초기화 */
    initFeedDetail(state) {
      state.selected = initialState.selected;
    },
    /** @dispatch 피드 단일 정보 갱신 */
    setFeedDetail(state, action) {
      state.selected = { ...action.payload };
    },
    /** @dispatch 피드 댓글 갱신 */
    updateFeedReply(state, action) {
      state.reply = {
        ...state.reply,
        ...action.payload,
        list: [...state.reply.list, ...action.payload.list],
      };
    },
    /** @dispatch 피드 댓글 앞에서 갱신 */
    updatePrevFeedReply(state, action) {
      state.reply = {
        ...state.reply,
        ...action.payload,
        list: [...action.payload.list, ...state.reply.list],
      };
    },
    /** @dispatch 리뷰 댓글 갱신 */
    updateReviewFeedReply(state, action) {
      state.reply = {
        ...state.reply,
        list: [...state.reply.list, ...action.payload],
      };
    },
    /** @dispatch 리뷰 댓글 앞에서 갱신 */
    updatePrevReviewFeedReply(state, action) {
      state.reply = {
        ...state.reply,
        list: [...action.payload, ...state.reply.list],
      };
    },
    /** @dispatch 피드 댓글 초기화 */
    initFeedReply(state) {
      state.reply = initialState.reply;
    },
    /** @dispatch 피드 댓글 정보 갱신 */
    setFeedReply(state, action) {
      state.reply.list = action.payload;
    },
    /** @dispatch 피드 댓글 작성, 리스트 갱신 */
    updateFeedReplyWrite(state, action) {
      state.reply.list = [action.payload, ...state.reply.list];
    },
  },
});

export const {
  updateFeedList,
  initFeedList,
  setFeedList,
  initFeedDetail,
  setFeedDetail,
  updateFeedReply,
  updatePrevFeedReply,
  updateReviewFeedReply,
  updatePrevReviewFeedReply,
  initFeedReply,
  setFeedReply,
  updateFeedReplyWrite,
} = FeedSlice.actions;

export default FeedSlice.reducer;
