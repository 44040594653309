import styled from 'styled-components';
// hooks
import useLazyImageObserver from 'hooks/common/file/useLazyImageObserver';
// styles
import { palette, onlyHover } from 'cds/styles';

/**
 * 클래스 커버 이미지
 * @param {object} props
 * @param {string} props.src 이미지 주소
 * @param {string} props.alt
 * @param {string} props.ratio height (세로 * 가로 * 100)%
 */

const ClassCover = ({ src, alt = '', ratio = '60.6061%' }) => {
  const { imageRef, imageSrc } = useLazyImageObserver({ src });

  return (
    <Layout>
      <Cover ref={imageRef} ratio={ratio} src={imageSrc} alt={alt} />
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 0;

  ${onlyHover} {
    &:hover {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

const Cover = styled.div.attrs(({ ratio, src }) => ({
  style: {
    paddingBottom: ratio,
    backgroundImage: `url(${src})`,
  },
}))`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
  border-radius: 8px;
  background-color: ${palette.grey010};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0.4px 0.4px 0 -0.4px ${palette.grey030}, inset 0 0 0 0.4px ${palette.grey030};
`;

export default ClassCover;
