const setDataToSessionStorage = (key: string, value: any) => {
  const item = sessionStorage.getItem(key);
  if (item) {
    return;
  }
  sessionStorage.setItem(key, value);
  return new Promise(res => {
    res(`${key} : ${value} insert well`);
  });
};

interface Data {
  [prop: string]: string;
}

const setDataToSessionStorageWithObject = (object: Data) => {
  Object.entries(object).forEach(([key, value]) => {
    const item = sessionStorage.getItem(key);
    if (item) {
      return;
    }
    value && sessionStorage.setItem(key, value);
  });
};

const setDataToLocalStorage = (key: string, value: any) => {
  const item = localStorage.getItem(key);
  if (item) {
    return;
  }
  localStorage.setItem(key, value);
};

const removeLocalData = (key: string) => {
  localStorage.removeItem(key);
};

const removeSessionData = (key: string[]) => {
  key.forEach(k => {
    sessionStorage.removeItem(k);
  });
};

const getLocalStorageItem = (key: string) => {
  const item = localStorage.getItem(key);
  if (item) {
    return item;
  }
  if (!item) {
    return null;
  }
};

export {
  setDataToLocalStorage,
  setDataToSessionStorageWithObject,
  setDataToSessionStorage,
  removeLocalData,
  removeSessionData,
  getLocalStorageItem,
};
