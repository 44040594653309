import request from 'api';
// utils
import * as commonUtils from 'lib/utils/common';

/* =============================== GET ================================ */

/**
 * 알림 목록 정보 호출 API
 * @version 3
 * @param {object} props
 * @param {number} props.page 1 이상의 페이지
 */
export const getNotificationList = ({ page = 1 }) => {
  return request.get(`/enfit1/api/v3/notify/${page}`);
};

/* =============================== POST =============================== */

/**
 * 알림 읽기 API
 * @version 3
 * @param {object} props
 * @param {number} props.notifyId 알림 ID
 */
export const readNotification = ({ notifyId }) => {
  return request.post(`/enfit1/api/v3/notify/${notifyId}`);
};

/**
 * 전체 알림 읽기 API
 * @version 3
 */
export const readAllNotification = () => {
  return request.post(`/enfit1/api/v3/notify/all`);
};

/**
 * 단일 알림 삭제 API
 * @version 3
 * @param {object} props
 * @param {number} props.notifyId 알림 ID
 */
export const removeNotification = ({ notifyId }) => {
  const data = commonUtils.createFormdata({ type: 'delete' });
  return request.post(`/enfit1/api/v3/notify/${notifyId}`, data);
};

/**
 * 전체 알림 삭제 API
 * @version 3
 */
export const removeAllNotification = () => {
  return request.post(`/enfit1/api/v3/notify/all_delete`);
};

/* =============================== PUT ================================ */

/* ============================== DELETE ============================== */
