/**
 * 터치 가능한 기기 여부 확인
 * @returns {boolean}
 */
export const isTouchableDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

/** 현재 기기 검사기 */
const deviceChecker = () => {
  function iPadApp() {
    return window.navigator.userAgent.match(/iphoneapp/i) ? true : false;
  }
  function iosApp() {
    return window.navigator.userAgent.match(/Modoo_ios/i) ? true : false;
  }
  function androidApp() {
    return window.navigator.userAgent.match(/Modoo_and/i) ? true : false;
  }
  function app() {
    return this.iosApp() || this.androidApp();
  }
  function ios() {
    return window.navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
  }
  function android() {
    return window.navigator.userAgent.match(/Android/i) ? true : false;
  }
  function mobile() {
    return this.ios() || this.android();
  }
  function version() {
    // 웹뷰에서 버전을 원활하게 캐치하지 못함.
    let agent = window.navigator.userAgent;
    agent = agent.split('Modoo_')[1];
    agent = agent && agent.replace(/[^0-9]/g, '');
    return agent;
  }

  function overVersion(version) {
    if (!version) {
      return false;
    }
    return this.version() >= version;
  }

  return {
    iPadApp: iPadApp,
    iosApp: iosApp,
    androidApp: androidApp,
    app: app,
    ios: ios,
    android: android,
    mobile: mobile,
    version: version,
    overVersion: overVersion,
  };
};

export default deviceChecker;
