import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial State
 * @type {import('types/slices/settings').InitialState}
 */

const initialState = {
  appPush: {
    advertising_flag: 'OFF',
    helpful_flag: 'OFF',
    cheer_flag: 'OFF',
    point_flag: 'OFF',
    friend_flag: 'OFF',
    reply_flag: 'OFF',
    chat_flag: 'OFF',
  },
};

/**
 * Slice
 */
const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    /** @dispatch 앱 푸쉬 상태 설정 */
    updateSettings(state, action) {
      state.appPush = action.payload;
    },
  },
});

export const { updateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
