import { useSelector as useSelector_, TypedUseSelectorHook } from 'react-redux';
// types
import { ReducerType } from 'slices';

/**
 * 타입 선언된 Redux useSelector
 * @example
 * const userInfo = useSelector(state => state.user.info);
 */
export const useSelector: TypedUseSelectorHook<ReducerType> = useSelector_;

export { useDispatch } from 'react-redux';
