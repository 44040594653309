/**
 * 길이 존재 여부 체크
 * @param {string} value
 * @returns {boolean}
 */
export const isEmptyString = value => {
  const valueLength = String(value).trim().length;

  if (!value) {
    return true;
  }
  return !valueLength;
};

/**
 * Object key값 존재 여부 체크
 * @param {object} value
 * @returns {boolean}
 */
export const isEmptyObject = value => {
  return Object.keys(value).length === 0 && value.constructor === Object;
};

/**
 * Window 객체 존재 여부 체크
 * @returns {boolean}
 */
export const isExistWindow = () => {
  if (typeof window !== 'undefined') {
    return true;
  }
  return false;
};
