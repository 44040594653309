import { createAsyncThunk } from '@reduxjs/toolkit';
// actions
import * as actions from 'slices/main';
// api
import * as recommendAPI from 'api/recommend';
import * as serviceAPI from 'api/service';

/**
 * @dispatch 키워드 기반 추천 데이터 호출
 * @param {object} props
 * @param {number} props.userId
 */
export const getKeywordClassList = createAsyncThunk(
  'main/getKeywordClassList',
  async ({ userId }, { dispatch }) => {
    const res = await recommendAPI.getKeywordClassList({ userId });

    dispatch(
      actions.setKeywordRecommend({
        keyword: res['keyword'],
        keyword_type: res['keyword_type'],
        recom_list: res['recom_list'],
      }),
    );

    return Promise.resolve(true);
  },
);

/**
 * @dispatch 기획전 추천 데이터 호출
 * @param {object} props
 * @param {number=} props.userId
 */
export const getEventClassList = createAsyncThunk(
  'main/getEventClassList',
  async ({ userId }, { dispatch }) => {
    const res = await recommendAPI.getEventClassList({ userId });

    dispatch(
      actions.setEventRecommend({
        recom_list: res['recom_list'],
      }),
    );

    return Promise.resolve(true);
  },
);

/**
 * @dispatch 메인 추천 클래스 데이터 호출
 * @param {object} props
 * @param {number} props.userId
 */
export const getMainClassList = createAsyncThunk(
  'main/getMainClassList',
  async ({ userId }, { dispatch }) => {
    const res = await recommendAPI.getMainClassList({ userId });

    dispatch(actions.setRecommendClassList(res['recom_list']));

    return Promise.resolve(true);
  },
);

/**
 * @dispatch 메인 배너 서비스 정보 요약 호출
 */
export const getServiceInfo = createAsyncThunk('common/getServiceInfo', async (_, { dispatch }) => {
  const res = await serviceAPI.getServiceInfo();

  dispatch(actions.setServiceInfo(res));

  return Promise.resolve(true);
});
