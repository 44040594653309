import styled from 'styled-components';
// components
import Icon from 'cds/icons';
// hooks
import useLazyImageObserver from 'hooks/common/file/useLazyImageObserver';
// utils
import { convertToWebp } from 'lib/utils/format/url';
// styles
import { palette, onlyHover } from 'cds/styles';

/**
 * 유저 이미지
 * @param {object} props
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} props.src
 * @param {string} props.alt
 */
const Avatar = ({ width, height, src, alt = '', isCoach, ...props }) => {
  const defaultImage = `${process.env.NEXT_PUBLIC_ASSET_PREFIX}/img/class/icon_user_profile.png`;

  const { imageRef, imageSrc } = useLazyImageObserver({
    src: src && src !== defaultImage ? convertToWebp(src) : defaultImage,
  });

  return (
    <Wrapper width={width} height={height} className="noselect">
      <Photo ref={imageRef} src={imageSrc} alt={alt} {...props}></Photo>
      {isCoach && <Icon name="ic_coach_badge_s" width={16} height={16} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  min-width: ${props => `${props.width}px`};
  width: ${props => `${props.width}px`};
  min-height: ${props => `${props.height}px`};
  height: ${props => `${props.height}px`};
  margin: 0 auto;
  border-radius: 100%;
  box-shadow: 0.4px 0.4px 0 -0.4px ${palette.grey030}, inset 0 0 0 0.4px ${palette.grey030};
  font-size: 0;

  img {
    cursor: pointer;
    object-fit: cover;
  }

  & > svg {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  ${onlyHover} {
    &:hover {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

const Photo = styled.div.attrs(({ src }) => ({
  style: {
    backgroundImage: `url(${src})`,
  },
}))`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: ${palette.background.bg2};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 0;
  overflow: hidden;
  border-radius: 100%;
`;

export default Avatar;
