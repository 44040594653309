import Script from 'next/script';

/**
 * Selper Kakao
 */
const KakaoAdvertisement = () => (
  <>
    <Script
      strategy="afterInteractive"
      src="https://t1.daumcdn.net/adfit/static/kp.js"
      onLoad={() => {
        window.kakaoPixel('2823312382539481544').pageView();
      }}
    />
    <Script
      type="text/javascript"
      charset="UTF-8"
      src="//t1.daumcdn.net/kas/static/kp.js"
      onLoad={() => {
        window.kakaoPixel('2823312382539481544').pageView();
      }}
    />
  </>
);
export default KakaoAdvertisement;
