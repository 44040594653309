import { createAsyncThunk } from '@reduxjs/toolkit';
// actions
import * as actions from 'slices/search';
// api
import * as searchAPI from 'api/search';

/**
 * @dispatch 최근 검색어 호출
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const getRecentKeyword = createAsyncThunk(
  'search/getRecentKeyword',
  async ({ userId }, { dispatch }) => {
    const res = await searchAPI.getRecentKeyword(userId);

    dispatch(actions.setRecentKeywordList(res));

    return Promise.resolve(true);
  },
);

/**
 * @dispatch 최근 검색어 제거
 * @param {object} props
 * @param {number} props.userId 유저 ID
 * @param {string} props.keywordId 검색어 ID
 */
export const deleteRecentKeyword = createAsyncThunk(
  'search/deleteRecentKeyword',
  async ({ userId, keywordId }, { getState, dispatch }) => {
    const recentKeywordList = getState().search.recentKeywordList;
    const filtered = recentKeywordList.filter(keyword => keyword.id !== keywordId);

    searchAPI.deleteRecentKeyword({ userId, keywordId });

    dispatch(actions.setRecentKeywordList(filtered));

    return Promise.resolve(true);
  },
);

/**
 * @dispatch 최근 검색어 전체 제거
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const deleteAllRecentKeyword = createAsyncThunk(
  'search/deleteAllRecentKeyword',
  async ({ userId }, { dispatch }) => {
    searchAPI.deleteAllRecentKeyword(userId);

    dispatch(actions.setRecentKeywordList([]));

    return Promise.resolve(true);
  },
);

/**
 * @dispatch 검색 결과 호출
 * @param {object} props
 * @param {string} props.keyword 검색어
 * @param {import('types/slices/search').CategorySort} props.sort 정렬 순서
 * @param {number} props.start
 * @param {number} props.end
 */
export const postClassSearch = createAsyncThunk(
  'search/postClassSearch',
  async ({ keyword, sort = 'match_score', start, end }, { getState, dispatch }) => {
    const userId = getState().user.info.id;
    const data = { keyword, order: sort, start, end };
    const res = await searchAPI.postClassSearch(userId, data);

    dispatch(actions.updateInfo({ totalCount: res['total_length'] }));
    dispatch(actions.updateClassList(res['list']));

    return Promise.resolve(res['list']);
  },
);

/**
 * @dispatch 클래스 검색 자동완성
 * @param {object} props
 * @param {string} props.keyword 검색어
 */
export const postAutoCompleteKeyword = createAsyncThunk(
  'search/postAutoCompleteKeyword',
  async ({ keyword }, { dispatch }) => {
    const res = await searchAPI.postAutoCompleteKeyword({ keyword });

    dispatch(actions.setAutoCompletedKeywordList(res['data_list']));

    return Promise.resolve(true);
  },
);

/** @dispatch 클래스 검색 랭킹 리스트 */
export const getSearchRanking = createAsyncThunk(
  'search/getSearchRanking',
  async (_, { dispatch }) => {
    const res = await searchAPI.getSearchRanking();

    dispatch(actions.setKeywordRankList(res['search_list']));

    return Promise.resolve(true);
  },
);
