/**
 * webp 주소로 변환한다.
 * 유저 이미지가 아닌 곳에 주로 사용한다.
 * @param {string} url
 * @returns {string}
 */
export const convertToWebp = url => {
  if (!url.startsWith(process.env.NEXT_PUBLIC_ASSET_PREFIX)) {
    return url;
  }

  if (!/sample/gi.test(url)) {
    return url
      .replace(/(\/original\/)|(\/temp\/)|(\/[photo|webp]+_+([0-9])+00\/)/g, '/webp_800/')
      .replace(/\.(png|bmp|gif|jpe?g)$/gi, '_$1.webp');
  }

  return url;
};

/**
 * gif 주소로 변환한다.
 * @param {string} url
 * @returns {string}
 */
export const convertToGif = url => {
  if (!url.startsWith(process.env.NEXT_PUBLIC_ASSET_PREFIX)) {
    return url;
  }

  if (!/sample/gi.test(url)) {
    return url
      .replace(/\/[photo|webp]+_+([0-9])+00\//g, '/original/')
      .replace(/(_gif)+(.*)/gi, '.gif');
  }

  return url;
};
