import { createSlice } from '@reduxjs/toolkit';

/**
 * InitialState
 * @type {import('types/slices/main').InitialState}
 */
const initialState = {
  serviceInfo: {},

  eventRecommend: {
    recom_list: [],
  },

  keywordRecommend: {
    keyword: '',
    keyword_type: '',
    recom_list: [],
  },

  recommendClassList: [],
};

/*
	Slice
*/
const MainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    /** @dispatch 키워드 기반 추천 정보 갱신 */
    setKeywordRecommend(state, action) {
      state.keywordRecommend = action.payload;
    },
    /** @dispatch 기획전 기반 추천 정보 갱신 */
    setEventRecommend(state, action) {
      state.eventRecommend = action.payload;
    },
    /** @dispatch 유저 추천 클래스 목록 갱신 */
    setRecommendClassList(state, action) {
      state.recommendClassList = action.payload;
    },
    /** @dispatch 유저 추천 클래스 목록 갱신 */
    setServiceInfo(state, action) {
      state.serviceInfo = action.payload;
    },
  },
});

export const { setKeywordRecommend, setEventRecommend, setRecommendClassList, setServiceInfo } =
  MainSlice.actions;

export default MainSlice.reducer;
