import request from 'api';
import { createFormdata } from 'lib/utils/common';

/* =============================== GET ================================ */

/**
 * 사용자 토큰 발급 API
 * @version 1
 */
export const getToken = () => {
  return request.get('/next/api/v1/enfitkey');
};

/**
 * 사용자 정보 호출 API - Heavy
 * @version 3
 */
export const getUserAll = () => {
  return request.get('/enfit1/api/v3/pilot/appMain');
};

/**
 * 사용자 정보 호출 API - Light
 * @version 3
 */
export const getUser = () => {
  return request.get('/enfit1/api/v3/pilot/appMainWeb');
};

/**
 * 사용자 정보 호출 (수정페이지) API
 * @version 3
 */
export const getEditProfile = () => {
  return request.get('/enfit1/api/v3/profile_edit');
};

/**
 * 확인하지 않은 챗, 알림 카운트 정보 호출 API
 * @version 3
 */
export const getNewsCount = () => {
  return request.get(`/enfit1/api/v3/newsCount`);
};

/**
 * 사용자 프로필 정보 호출 API
 * @version 3
 *
 * @param {object} props
 * @param {number} props.userId 사용자 ID
 * @param {number} props.page
 */
export const getProfile = ({ userId, page = 1 }) => {
  return request.get(`/enfit1/api/v3/interested/profile/${userId}/${page}`, {
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_DEFAULT_TOKEN}`,
    },
  });
};

/**
 * 사용자 부가 정보 호출 API (레벨링, 적립금, 팔로잉)
 * @version 3
 */
export const getUserSubInfo = () => {
  return request.get(`/enfit1/api/v3/profile_app`);
};

/**
 * 사용자 포인트 리스트 호출 API
 * @version 3
 */
export const getUserPointList = ({ page }) => {
  return request.get(`/enfit1/api/v3/coin_point/${page}`);
};

/**
 * 최근 본 클래스 호출 API
 * @version 1
 */
export const getRecentClassList = ({ userId }) => {
  return request.get(`/enfit2/api/v1/log/view-class/${userId}`);
};

/**
 * 관심 클래스 호출 API
 * @version 3
 *
 * @param {object} data
 * @param {number} data.skip
 * @param {number} data.limit
 * @param {number} data.userId 유저 ID
 */
export const getBookmarkClassList = ({ skip, limit, userId }) => {
  const params = { skip, limit };
  return request.get(`/enfit2/api/v1/log/wishlist-class/${userId}`, { params });
};

/**
 * 사용자 팔로우 리스트 호출 API
 * @version 3
 *
 * @param {object} data
 * @param {number} data.userId
 * @param {number} data.page 현재 페이지
 * @param {number} data.limit 한 번에 가져올 개수
 */
export const getFollow = ({ userId, page, limit = 20 }) => {
  const params = {
    page,
    limit_no: limit,
  };
  return request.get(`/enfit1/api/v3/following/${userId}`, { params });
};

/**
 * 사용자 팔로워 리스트 호출 API
 * @version 3
 *
 * @param {object} data
 * @param {number} data.userId
 * @param {number} data.page 현재 페이지
 * @param {number} data.limit 한 번에 가져올 개수
 */
export const getFollower = ({ userId, page, limit = 20 }) => {
  const params = {
    page,
    limit_no: limit,
  };
  return request.get(`/enfit1/api/v3/follower/${userId}`, { params });
};

/**
 * 사용자 차단 리스트 호출 API
 * @version 3
 *
 * @param {object} data
 * @param {number} data.page 현재 페이지
 * @param {number} data.limit 한 번에 가져올 개수
 */
export const getBlockedUser = ({ page, limit }) => {
  const params = {
    page,
    limit_no: limit,
  };
  return request.get(`/enfit1/api/v3/poor`, { params });
};

/**
 * 회원 탈퇴 가능 여부 호출 API
 * @version 3
 */
export const getCheckSecession = () => {
  return request.get(`/enfit1/api/v3/withdrawal_check`);
};

/* =============================== POST =============================== */

/**
 * 휴대폰 로그인 사용자 계정 확인 API
 * @param {object} data
 * @param {number | string} data.user_id 연락처(유저 아이디)
 * @param {number | string} data.passwd  비밀번호
 */
export const postUserAuth = async data => {
  return request.post('/enfit1/api/v3/login/Auth', new URLSearchParams(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
};

/**
 * 사용자 휴대폰번호 인증번호 받기 API
 * @param {object} data
 * @param {number | string} data.phone 연락처(유저 아이디)
 */
export const postSendAuth = async data => {
  return request.post(
    `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/modoo/ajaxSendAuth`,
    new URLSearchParams(data),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
  );
};

/**
 * 제휴 사용자 비밀번호 설정 이메일 발송 API
 * @param {object} data
 * @param {number} data.company // not
 * @param {number} data.department // not
 * @param {string} data.phone
 * @param {string} data.name
 * @param {number} data.business_flag
 * @param {number} data.trainer
 * @param {number} data.use_agree
 * @param {number} data.private_agree
 * @param {number} data.third_agree
 */
export const postRegisterContactMember = data => {
  const DEFAULT_PATH = '/new';
  return request.post(`${DEFAULT_PATH}/user_register`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf8',
    },
  });
};

/**
 * Biz 사용자 비밀번호 설정 이메일 발송 API
 * @param {object} data
 * @param {number} data.company // not
 * @param {number} data.department // not
 * @param {string} data.phone
 * @param {string} data.name
 * @param {number} data.business_flag
 * @param {number} data.trainer
 * @param {number} data.use_agree
 * @param {number} data.private_agree
 * @param {number} data.third_agree
 */
export const postRegisterBizMember = data => {
  const DEFAULT_PATH = '/new';
  return request.post(`${DEFAULT_PATH}/cms/user/trainer/customer/storeRegister?apipass`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf8',
    },
  });
};

/**
 * Biz 사용자 비밀번호 설정 이메일 발송 API
 * @param {object} data
 * @param {number | string} data.hpno 연락처(유저 아이디)
 * @param {number | string} data.authno 인증번호
 */
export const postFindMyPassword = data => {
  const DEFAULT_PATH = '/new';
  return request.post(`${DEFAULT_PATH}/biz/user/reset_password`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf8',
    },
  });
};

/**
 * 사용자 휴대폰번호 인증번호 확인 API
 * @param {object} data
 * @param {number | string} data.hpno 연락처(유저 아이디)
 * @param {number | string} data.authno 인증번호
 */
export const postCheckSmsAuth = data => {
  return request.post(
    `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/modoo/ajaxSmsAuth`,
    new URLSearchParams(data),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
  );
};

/**
 * 사용자 정보 수정 API
 * @version 3
 *
 * @param {FormData} data
 * @param {string?} data.nickname 닉네임
 * @param {string?} data.profile_comment 소개
 * @param {string?} data.email 이메일
 * @param {string?} data.sns_instagram 인스타그램 URL
 * @param {string?} data.sns_facebook 페이스북 URL
 * @param {string?} data.sns_youtube 유튜브 URL
 * @param {string?} data.sns_homepage 홈페이지 URL
 */
export const postChangeProfile = data => {
  return request.post('/enfit1/api/v3/user', data);
};

/**
 * 사용자 프로필 사진 변경 API
 * @version 3
 *
 * @param {FormData} data
 * @param {File} data.file 사진 파일
 */
export const postChangeProfilePhoto = data => {
  return request.post('/enfit1/api/v3/photo', data);
};

/**
 * 사용자 연락처 변경 API
 * @version 3
 *
 * @param {object} data
 * @param {number} data.userId 사용자 ID
 * @param {number} data.phoneNumber 휴대폰 번호
 * @param {string} data.kakaoId 카카오 ID
 * @param {'Y' | 'N'} data.isAbroad 해외 거주 여부
 */
export const postChangeProfileNumber = ({ userId, phoneNumber, kakaoId, isAbroad }) => {
  const data = createFormdata({
    phone: phoneNumber,
    kakako_id: kakaoId,
    living_abroad: isAbroad,
  });
  return request.post(`/enfit1/api/v3/coach/${userId}`, data);
};

/**
 * 관심 추가/삭제 API
 * @version 3
 *
 * @param {object} data
 * @param {FormData} data.data 추가의 경우 data가 필요없음.
 * @param {number | string} data.classId 클래스 아이디
 */
export const postBookmark = ({ classId, data }) => {
  return request.post(`/enfit1/api/v3/have/${classId}`, data);
};

/**
 * 해당 피드 사용자 차단 API
 * @version3
 *
 * @param {object} data
 * @param {string} data.userId 유저 ID
 */
export const postBlockUser = ({ userId }) => {
  return request.post(`/enfit1/api/v3/poorUser/${userId}`);
};

/**
 * 사용자 차단 해제 API
 * @version 3
 *
 * @param {object} data
 * @param {number} data.blockUserId 차단 해제할 유저 아이디
 */
export const postCancelBlockedUser = ({ blockUserId }) => {
  const data = new FormData();
  data.append('mode', 'delete');
  return request.post(`/enfit1/api/v3/poorUser/${blockUserId}`, data);
};

/**
 * 회원 탈퇴 API
 * @version 3
 */
export const postSecession = () => {
  return request.post(`/enfit1/api/v3/withdrawal`);
};

/* =============================== PUT ================================ */

/* ============================== DELETE ============================== */
