import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
// components
import BaseHeader, { HeaderBlank } from 'components/layout/headers/Base';
import MobileSearchInput from 'components/molecules/headers/MobileSearchInput';
import AutoComplete from 'components/molecules/modal/search/AutoComplete';
import IconButton from 'cds/buttons/Icon';
import Icon from 'cds/icons';
// slices
import { postAutoCompleteKeyword } from 'slices/search.thunk';
// hooks
import { useSelector, useDispatch } from 'hooks/common/useStore';
import useModal from 'hooks/common/useModal';
import useThrottle from 'hooks/common/event/useThrottle';
// utils
import deviceChecker from 'lib/utils/deviceChecker';
// styles
import { mediaValue } from 'cds/styles';

/**
 * 검색 헤더
 * @param {object} props
 * @param {Function} props.onBack
 */
const SearchHeader = ({ onBack, ...props }) => {
  const router = useRouter();

  const dispatch = useDispatch();
  const autoCompletedClassList = useSelector(state => state.search.autoCompletedKeywordList);

  const [isIpadApp, setIsIpadApp] = useState(false);
  const [isShowAutoComplete, setIsShowAutoComplete] = useState(false);
  const [word, setWord] = useState('');
  const isMounted = useRef(false);
  const isSearched = useRef(false);

  const { onClearModal } = useModal();
  const throttle = useThrottle();

  const onBack_ = () => (onBack ? onBack() : router.back());

  const onKeyUp = e => {
    if (e.key === 'Enter') {
      e.target.blur();

      setIsShowAutoComplete(false);
      onClearModal();
      isSearched.current = true;
    } else {
      isSearched.current = false;
    }

    setWord(e.target.value);
  };

  const onChange = throttle.run(e => {
    const { value } = e.target;

    dispatch(postAutoCompleteKeyword({ keyword: value })).finally(() => {
      if (!isMounted.current) {
        return;
      }

      if (value.trim().length > 0 && !isSearched.current) {
        setIsShowAutoComplete(true);
      } else {
        setIsShowAutoComplete(false);
      }
    });
  }, 400);

  const onCloseAutoComplete = () => setIsShowAutoComplete(false);

  // ipadApp check
  useEffect(() => {
    const device = deviceChecker();
    setIsIpadApp(device.iPadApp());
  }, []);

  useEffect(() => {
    isSearched.current = false;
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  return (
    <>
      <BaseHeader>
        <ContentLayout>
          <IconButton width={52} height={54} onClick={onBack_}>
            <Icon name="ic_back_m" width={24} height={24} />
          </IconButton>
          {!isIpadApp && <MobileSearchInput onKeyUp={onKeyUp} onChange={onChange} {...props} />}
        </ContentLayout>
      </BaseHeader>
      <HeaderBlank />

      {isShowAutoComplete && (
        <AutoComplete
          keyword={word}
          keywordList={autoCompletedClassList}
          onClose={onCloseAutoComplete}
          showBackground
        />
      )}
    </>
  );
};

const ContentLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
  width: ${mediaValue.medium}px;
  height: 100%;
  padding: 0 16px 0 0;
  margin: auto;

  & > button {
    padding: 0 12px 0 16px;
  }
`;

export default SearchHeader;
