import styled from 'styled-components';
import Link from 'next/link';
// components
import HorizontalSlider from 'components/molecules/common/HorizontalSlider';
import FillButton from 'cds/buttons/Fill';
import TextButton from 'cds/buttons/Text';
import IconButton from 'cds/buttons/Icon';
import Icon from 'cds/icons';
// slices
import { deleteRecentKeyword, deleteAllRecentKeyword } from 'slices/search.thunk';
// hooks
import { useSelector, useDispatch } from 'hooks/common/useStore';
import useModal from 'hooks/common/useModal';
// styles
import { palette } from 'cds/styles';
import * as typography from 'cds/typography';

/**
 * 최근 검색어
 * @param {object} props
 * @param {import('types/slices/search').Keyword[]} props.keywordList 검색어 리스트
 */
const RecentKeyword = ({ keywordList = [] }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.user.info);

  const { onClearModal } = useModal();

  const onRemoveAll = () => {
    if (!userInfo.isLoaded) {
      return;
    }

    dispatch(deleteAllRecentKeyword({ userId: userInfo.id }));
  };

  const onRemove = (e, keywordId) => {
    e.stopPropagation();

    if (!userInfo.isLoaded) {
      return;
    }

    dispatch(deleteRecentKeyword({ userId: userInfo.id, keywordId }));
  };

  const onClose = () => onClearModal();

  const KeywordList = keywordList.map(keyword => (
    <Link key={keyword.id} href={`/search?keyword=${keyword.keyword}`} passHref>
      <ChipButton color={palette.grey005} onClick={onClose}>
        {keyword.keyword}
        <IconButton as={'div'} width={24} height={32} onClick={e => onRemove(e, keyword.id)}>
          <Icon name="ic_close_xs" width={16} height={16} fill={palette.grey060} />
        </IconButton>
      </ChipButton>
    </Link>
  ));

  return (
    <section>
      <TitleSection>
        <h1>최근 검색어</h1>
        <TextButton size="ExtraSmall" color={palette.font.tertiary2} onClick={onRemoveAll}>
          전체 삭제
        </TextButton>
      </TitleSection>

      <KeywordLayout>
        <HorizontalSlider>{KeywordList}</HorizontalSlider>
      </KeywordLayout>
    </section>
  );
};

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 16px;

  & > h1 {
    ${typography.subtitle3_medium};
  }

  & > button {
    flex-grow: 0;
    margin-right: -4px;
  }
`;

const KeywordLayout = styled.div`
  padding-bottom: 16px;
`;

const ChipButton = styled(FillButton)`
  flex-grow: 0;
  height: 32px;
  border-radius: 32px;
  padding-left: 12px;
  white-space: nowrap;
  color: ${palette.grey080};
  font-size: 14px;

  &::before {
    z-index: 0;
  }

  & > div {
    padding-right: 8px;
    margin-left: 4px;
    z-index: 1;
  }

  & ~ & {
    margin-left: 8px;
  }
`;

export default RecentKeyword;
