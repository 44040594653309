import { css } from 'styled-components';
// styles
import { mediaQuery } from './styles';

/* ------------------------ Titles ----------------------- */

export const h1_bold = css`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 54px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }
`;

export const h1_medium = css`
  font-size: 32px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 54px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }
`;

export const h2_bold = css`
  font-size: 26px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }
`;

export const h2_medium = css`
  font-size: 26px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }
`;

export const h3_bold = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }
`;

export const h3_medium = css`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 36px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }
`;

// cds v2
export const heading2_600 = css`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
`;

/* ----------------------- Subtitles ---------------------- */

export const subtitle1_bold = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }
`;

export const subtitle1_medium = css`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }
`;

export const subtitle2_bold = css`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.03em;

  ${mediaQuery.large} {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.03em;
  }
`;

export const subtitle2_medium = css`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.03em;

  ${mediaQuery.large} {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.03em;
  }
`;

export const subtitle3_bold = css`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.05em;

  ${mediaQuery.large} {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.05em;
  }
`;

export const subtitle3_medium = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.05em;

  ${mediaQuery.large} {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.05em;
  }
`;

export const subtitle4_bold = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42;
  letter-spacing: -0.06em;

  ${mediaQuery.large} {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.06em;
  }
`;

export const subtitle4_medium = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42;
  letter-spacing: -0.06em;

  ${mediaQuery.large} {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.06em;
  }
`;

export const subtitle5_bold = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42;
  letter-spacing: -0.06em;

  ${mediaQuery.large} {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.05em;
  }
`;

export const subtitle5_medium = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42;
  letter-spacing: -0.06em;

  ${mediaQuery.large} {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.05em;
  }
`;

/* ----------------------- Body ---------------------- */

export const body1_regular = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.05em;

  ${mediaQuery.large} {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.05em;
  }
`;

export const body2_regular = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42;
  letter-spacing: -0.06em;

  ${mediaQuery.large} {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375;
    letter-spacing: -0.06em;
  }
`;

export const body3_regular = css`
  font-size: 13px;
  font-weight: 400;
  line-height: 1.53;
  letter-spacing: -0.03em;

  ${mediaQuery.large} {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.42;
    letter-spacing: -0.05em;
  }
`;

export const body4_regular = css`
  font-size: 13px;
  font-weight: 400;
  line-height: 1.53;
  letter-spacing: -0.03em;

  ${mediaQuery.large} {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42;
    letter-spacing: -0.03em;
  }
`;

// cds v2
export const body1_600 = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`;

export const body1_500 = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
`;

export const body2_400 = css`
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
`;

export const body3_400 = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const body3_500 = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;

export const caption1_500 = css`
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
`;

/* ---------------------- Caption --------------------- */
export const caption1_medium = css`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.53;
  letter-spacing: -0.03em;

  ${mediaQuery.large} {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42;
    letter-spacing: -0.03em;
  }
`;

export const caption2_bold = css`
  font-size: 12px;
  font-weight: 700;
  line-height: 1.75;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.53;
    letter-spacing: -0.02em;
  }
`;

export const caption2_medium = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: -0.02em;
  }
`;

export const caption2_regular = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: -0.02em;
  }
`;

export const caption3_medium = css`
  font-size: 11px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: -0.02em;
  }
`;

export const caption3_regular = css`
  font-size: 11px;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: -0.02em;
  }
`;

export const caption4_regular = css`
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.02em;

  ${mediaQuery.large} {
    font-size: 11px;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: -0.02em;
  }
`;

// cds v2
export const caption1_400 = css`
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
`;

export const caption2_500 = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
`;

export const caption3_500 = css`
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
`;

export const pretendard = css`
  font-family: 'Pretendard Std', Pretendard, 'Noto Sans KR', -apple-system, BlinkMacSystemFont,
    system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;
`;
