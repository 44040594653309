import styled from 'styled-components';
import { useEffect } from 'react';
// components
import SearchHeader from 'components/organisms/headers/Search';
import ClassListSlider from 'components/molecules/class/ClassListSlider';
import RecentKeyword from 'components/molecules/modal/search/RecentKeyword';
import CategoryList from 'components/molecules/modal/search/CategoryList';
import VerticalScrollLayout from 'cds/layout/VerticalScroll';
// slices
import { getRecentClassList } from 'slices/user.thunk';
import { getCategoryList } from 'slices/category.thunk';
import { getRecentKeyword } from 'slices/search.thunk';
import { getMainClassList } from 'slices/main.thunk';
// hooks
import { useSelector, useDispatch } from 'hooks/common/useStore';
// styles
import { palette, mediaValue } from 'cds/styles';
import * as typography from 'cds/typography';

/**
 * 검색 모달
 */
const Search = ({ PreventModalOff, ModalOff }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(state => state.user.info);
  const categoryList = useSelector(state => state.category.categoryList);
  const recentKeywordList = useSelector(state => state.search.recentKeywordList);
  const recentClassList = useSelector(state => state.user.recentClassList);
  const recommendClassList = useSelector(state => state.main.recommendClassList);

  const onClose = () => {
    ModalOff();
    window.history.back();
  };

  /* eslint-disable */
  useEffect(() => {
    const onHashChaged = () => ModalOff();

    window.addEventListener('popstate', onHashChaged);
    return () => window.removeEventListener('popstate', onHashChaged);
  }, []);
  /* eslint-enable */

  /** 카테고리 데이터 호출 */
  useEffect(() => {
    if (categoryList.length) {
      return;
    }
    dispatch(getCategoryList());
  }, [dispatch, categoryList]);

  /** 최근 검색어 데이터 호출 */
  useEffect(() => {
    if (userInfo.isLoaded && userInfo.id) {
      dispatch(getRecentKeyword({ userId: userInfo.id }));
    }
  }, [dispatch, userInfo]);

  /** 추천 클래스 데이터 호출 */
  useEffect(() => {
    if (userInfo.isLoaded && userInfo.id && recommendClassList.length === 0) {
      dispatch(getMainClassList({ userId: userInfo.id }));
    }
  }, [dispatch, userInfo, recommendClassList]);

  /** 최근 클래스 데이터 호출 */
  useEffect(() => {
    if (userInfo.isLoaded && userInfo.id) {
      dispatch(getRecentClassList({ userId: userInfo.id }));
    }
  }, [dispatch, userInfo]);

  return (
    <Layout onMouseDown={PreventModalOff} onClick={PreventModalOff}>
      <Inner>
        <SearchHeader onBack={onClose} autoFocus />

        {recentKeywordList.length > 0 && (
          <>
            <RecentKeyword keywordList={recentKeywordList} />
            <Divider />
          </>
        )}

        <CategoryList categoryList={categoryList} onClose={ModalOff} />
        <Divider />

        {recentClassList.length > 0 && (
          <>
            <TitleSection>
              <h1>최근 본 클래스</h1>
            </TitleSection>
            <ClassListSlider
              list={recentClassList}
              slidePerView={[
                {
                  break: 'default',
                  view: 2.04,
                },
                {
                  break: mediaValue.small,
                  view: 3.04,
                },
              ]}
            />
          </>
        )}
        {recentClassList.length === 0 && (
          <>
            <TitleSection>
              {userInfo.id && <h1>{userInfo.name}님을 위한 추천 클래스</h1>}
              {!userInfo.id && <h1>오늘의 추천 클래스</h1>}
            </TitleSection>
            <ClassListSlider
              list={recommendClassList}
              slidePerView={[
                {
                  break: 'default',
                  view: 2.04,
                },
                {
                  break: mediaValue.small,
                  view: 3.04,
                },
              ]}
            />
          </>
        )}
      </Inner>
    </Layout>
  );
};

const Layout = styled(VerticalScrollLayout)`
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
  overflow-y: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Inner = styled.div`
  width: ${mediaValue.medium}px;
  max-width: 100%;
  margin: auto;
`;

const Divider = styled.hr`
  height: 10px;
  margin: 0;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${palette.border.b1};
  background-color: ${palette.background.bg2};
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  padding: 0 16px;

  & > h1 {
    ${typography.subtitle3_medium};
  }
`;

export default Search;
