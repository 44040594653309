import { createSlice } from '@reduxjs/toolkit';

/**
 * InitialState
 * @type {import('types/slices/event').InitialState}
 */
const initialState = {
  eventList: [],

  month: {
    categoryList: [],
    classList: [],
  },

  plan: {
    info: {
      title: '',
      banner_image: '',
      bgcolor: '',
    },
    list: [],
  },

  banner: [],
};

/*
	Slice
*/
const EventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    /** @dispatch 이벤트 리스트 갱신 */
    updateEventList(state, action) {
      state.eventList = action.payload;
    },
    /** @dispatch 초저가 이벤트 갱신 */
    updateMonth(state, action) {
      state.month = { ...state.month, ...action.payload };
    },
    /** @dispatch 기획전 갱신 */
    setPlan(state, action) {
      state.plan = action.payload;
    },
    /** @dispatch 기획전 초기화 */
    initPlan(state) {
      state.plan = initialState.plan;
    },
    /** @dispatch 이벤트 배너 갱신 */
    updateEventBanner(state, action) {
      state.banner = [...action.payload];
    },
  },
});

export const { updateEventList, updateMonth, setPlan, initPlan, updateEventBanner } =
  EventSlice.actions;

export default EventSlice.reducer;
