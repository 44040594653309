import styled from 'styled-components';
// styles
import { palette } from '../styles';

/**
 * 아이콘 버튼
 * @param width
 * @param height
 * @param mode
 */
const IconButton = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};

  & svg {
    fill: ${({ mode }) => (mode === 'Dark' ? palette.white : '')};
  }
`;

interface Props {
  width?: number;
  height?: number;
  mode?: 'Light' | 'Dark';
}

export default IconButton;
