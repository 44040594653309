import styled, { css } from 'styled-components';
// components
import StarIcon from 'components/atoms/icons/Star';
import ClassCover from 'components/atoms/photos/ClassCover';
import Avatar from 'components/atoms/photos/Avatar';
// hooks
import { useDispatch, useSelector } from 'hooks/common/useStore';
import useCommonBridge from 'hooks/bridge/useCommonBridge';
import useWebview from 'hooks/common/useWebview';
// slice
import { postBookmark } from 'slices/user.thunk';
// utils
import { formatToPrice } from 'lib/utils/format/number';
// styles
import * as typography from 'cds/typography';
import { palette, mediaQuery } from 'cds/styles';
import Icon from 'cds/icons';
import IconButton from 'cds/buttons/Icon';
import { findClickedData, setClickedItems } from 'slices/dataMining';

/**
 * 클래스 아이템 column 타입 카드
 * @param {object} props
 * @param {import('types/slices/main').RecommendClass} props.data 클래스 정보
 * @param {boolean} props.showProfile 프로필 사진 노출 여부
 * @param {boolean} props.showSalePer 할인율 노출 여부
 * @param {boolean} props.showPrice 가격 노출 여부
 * @param {'class' | 'banner'} props.variant 배너 여부
 * @param {boolean} props.showBookmark 북마크 추가/제거 기능 여부
 */
const ColumnItem = ({ data, showProfile, showSalePer, showPrice, variant, showBookmark }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.user.info);

  const { goToClassDetail, goToProfile } = useCommonBridge();
  const { isWebview } = useWebview();

  const coverSrc = variant === 'banner' ? data.image_small : data.class_photo;

  const onBookmark = e => {
    e.stopPropagation();

    const isMode = data.class_have_status === 'Y' || !data.class_have_status ? 'delete' : 'add';
    dispatch(postBookmark({ classId: data.class_id, type: isMode }));
  };

  const linkDelegator = () => {
    if (data.link) {
      window.location.href = data.link;
      return;
    }

    if (!userInfo.id) {
      window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/classDetail/${data.class_id}`;
      return;
    }
    return goToClassDetail(data.class_id);
  };

  const onMouseDownCard = e => {
    let targetURL = '';

    if (isWebview) {
      return;
    }

    if (e.buttons === 4) {
      e.stopPropagation();
      if (variant !== 'banner') {
        targetURL = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/classDetail/${data.class_id}`;
      } else {
        targetURL = data.link;
      }

      window.open(targetURL, '_blank');
    }
  };

  const profileDelegator = e => {
    e.stopPropagation();
    goToProfile(data.coach_id);
  };

  const onMouseDownProfile = e => {
    if (isWebview) {
      return;
    }

    if (e.buttons === 4) {
      e.stopPropagation();
      window.open(`${process.env.NEXT_PUBLIC_DEFAULT_HOST}/new/user/${data.coach_id}`, '_blank');
    }
  };

  const onClickColumnItem = () => {
    dispatch(findClickedData(data.class_name));
    dispatch(setClickedItems(data));
  };

  return (
    <Item
      onClick={() => {
        linkDelegator();
        onClickColumnItem();
      }}
      onMouseDown={onMouseDownCard}
    >
      <ClassCover ratio={variant === 'banner' ? '100%' : '60.6061%'} src={coverSrc} />

      {showBookmark && (
        <BookmarkButton onClick={onBookmark}>
          {data.class_have_status === 'Y' || !data.class_have_status ? (
            <Icon name="ic_bookmark_active_m" fill={palette.red040} width="19" height="23" />
          ) : (
            <Icon
              name="ic_bookmark_inactive_m"
              stroke="#fff"
              fill={palette.dim.hover}
              width="19"
              height="23"
            />
          )}
        </BookmarkButton>
      )}

      {variant !== 'banner' && (
        <Info showProfile={showProfile}>
          <div>
            <AvatarButton onClick={profileDelegator} onMouseDown={onMouseDownProfile}>
              <Avatar width={32} height={32} src={data.coach_photo} />
            </AvatarButton>
          </div>
          <div>
            <Title>{data.class_name}</Title>

            <Summary>
              <Coach onClick={profileDelegator} onMouseDown={onMouseDownProfile}>
                {data.coach_name}
              </Coach>
              {data.curriculum_cnt && (
                <CurriculumCount>
                  <span>{`${data.curriculum_cnt}강`}</span>
                </CurriculumCount>
              )}
            </Summary>

            {(showSalePer || showPrice) && (
              <Price>
                {showSalePer && !!parseInt(data.payment_info_data1 || data.price_sale, 10) && (
                  <SalePer>{Math.floor(data.payment_info_data1 || data.price_sale)}%</SalePer>
                )}
                {showPrice && (data.payment || data.payment_info_data4 || data.payment_price) && (
                  <SalePrice>
                    <span>월</span>
                    {formatToPrice(data.payment_info_data4 || data.payment || data.payment_price)}원
                  </SalePrice>
                )}
              </Price>
            )}

            {!!(data.review_score || data.class_star_avg || data.signup_cnt) && (
              <Status>
                {(!!data.review_score || !!data.class_star_avg) && (
                  <span>
                    <StarIcon type="S" isActive />
                    {data.review_score
                      ? data.review_score.toFixed(1)
                      : data.class_star_avg && data.class_star_avg.toFixed(1)}
                  </span>
                )}
                {!!data.signup_cnt && <span>{formatToPrice(data.signup_cnt)}명 수강</span>}
              </Status>
            )}
          </div>
        </Info>
      )}
    </Item>
  );
};

const skeleton = css`
  background-color: ${palette.grey010};
  border-radius: 4px;
`;

const Item = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  border-radius: 8px;

  &:active {
    background-color: ${palette.grey010};
  }
`;

const BookmarkButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: 1;
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;

  & > div:first-of-type {
    display: ${({ showProfile }) => (showProfile ? 'block' : 'none')};
    flex: 0 auto;
    width: 32px;
    padding: 4px 0 0;
    margin: 0 12px 0 0;
  }

  & > div:last-of-type {
    overflow: hidden;
    flex: 0 auto;
  }

  ${mediaQuery.medium} {
    & > div:first-of-type {
      display: block;
    }
  }
`;

const AvatarButton = styled.button`
  flex: 0 auto;
  width: 32px;
  height: 32px;
`;

const Title = styled.p`
  display: block;
  display: -webkit-box;
  position: relative;
  padding: 0;
  margin-bottom: 4px;
  ${typography.subtitle4_medium}
  color: ${palette.grey080};
  white-space: break-spaces;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  &::after {
    content: '...';
    text-align: right;
    bottom: 0;
    right: 0;
    width: 25%;
    display: block;
    position: absolute;
    height: calc(1em * 1.2);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%);
  }

  @supports (-webkit-line-clamp: 2) {
    &::after {
      display: none;
    }
  }

  &:empty {
    ${skeleton};
    height: 20px;
  }

  ${mediaQuery.large} {
    font-size: 15px;
  }
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  ${typography.caption3_regular}
  color: ${palette.grey040};

  ${mediaQuery.large} {
    ${typography.caption2_regular}
  }
`;

const Coach = styled.span`
  overflow: hidden;
  flex: 0 auto;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:empty {
    ${skeleton};
    width: 150px;
    height: 16px;
  }
`;

const CurriculumCount = styled.span`
  flex: 0 0 auto;

  &::before {
    content: '·';
    margin: 0 4px;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const SalePer = styled.span`
  flex: 0 auto;
  margin-right: 6px;
  ${typography.subtitle3_bold}
  color: ${palette.red060};
  font-weight: 700;

  ${mediaQuery.large} {
    font-size: 16px;
    letter-spacing: 0;
  }

  &:empty {
    ${skeleton};
    width: 28px;
    height: 22px;
  }
`;

const SalePrice = styled.span`
  display: flex;
  align-items: baseline;
  flex: 0 auto;
  ${typography.subtitle3_bold}
  color: ${palette.grey080};

  & span {
    margin-right: 4px;
    font-size: 14px;
  }

  &:empty {
    ${skeleton};
    width: 76px;
    height: 22px;
  }

  ${mediaQuery.large} {
    font-size: 16px;
    letter-spacing: 0;
  }
`;

const Status = styled.p`
  display: flex;
  align-items: center;
  ${typography.body3_regular}
  color: ${palette.grey080};

  ${mediaQuery.large} {
    font-size: 13px;
  }

  & span {
    display: flex;
    align-items: center;
    flex: 0 auto;

    svg {
      margin-right: 2px;
    }
  }

  & span ~ span::before {
    display: inline-block;
    content: '';
    width: 1px;
    margin: 0 4px;
    height: 8px;
    background: ${palette.grey040};
  }

  &:empty {
    ${skeleton};
    width: 110px;
    height: 18px;
  }
`;

export default ColumnItem;
