import styled from 'styled-components';

/**
 * Vertical 스크롤 레이아웃
 */
const VerticalScrollLayout = styled.div`
  padding-bottom: 64px;
`;

export default VerticalScrollLayout;
