// components
import Icon from 'cds/icons';

/**
 * Star 아이콘
 * @param {object} props
 * @param {'S' | 'M' | 'L' | 'XL' | 'XXL'} props.type 사이즈 타입
 * @param {boolean=} props.isActive 색 존재 여부
 */
const StarIcon = ({ type, isActive, ...props }) => {
  const sizeMap = {
    S: 16,
    M: 20,
    L: 30,
    XL: 40,
    XXL: 50,
  };
  const w = sizeMap[type];
  const h = w;
  const fill = isActive ? '#FFD912' : '#DADBDC';

  return <Icon name="ic_star" width={w} height={h} fill={fill} {...props} />;
};

export default StarIcon;
