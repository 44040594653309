import { getLocalStorageItem } from 'lib/utils/storageItem';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClickableInformation, setPageTitle, setQueryInfo, setUuid } from 'slices/dataMining';
import { postDataMiningDataLoad, postDataMiningDataUnload } from 'slices/dataMining.thunk';
import { v4 as uuidV4, validate as uuidValidate } from 'uuid';
const useDataMining = ({ subscribeList }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setClickableInformation(subscribeList));
  }, [subscribeList, dispatch]);
};

const useSetURLData = (router, dispatch) => {
  useEffect(() => {
    const title = document.head.querySelector('title').textContent;
    dispatch(setPageTitle(title));
    const queryInfo = router.query;
    queryInfo && dispatch(setQueryInfo(queryInfo));
  }, [router, dispatch]);
};

const useAttachPageEvent = (router, dispatch) => {
  const { BROWSER_MODE } = useSelector(({ dataMining }) => ({
    BROWSER_MODE: dataMining.BROWSER_MODE,
  }));
  useEffect(() => {
    const handleBrowserLoad = () => {
      dispatch(postDataMiningDataLoad());
    };
    const handBrowserUnload = () => {
      dispatch(postDataMiningDataUnload());
    };

    // TODO : 페이지 진입, 탈출 로직 재검토 필요
    // 페이지 떠나기 전, 창 닫기 전 전송
    if (router.isReady && BROWSER_MODE === 'NOT_INITIAL') {
      router.events.on('routeChangeStart', handBrowserUnload);
      addEventListener('beforeunload', handleBrowserLoad);
      return () => {
        router.events.off('routeChangeStart', handBrowserUnload);
        removeEventListener('beforeunload', handleBrowserLoad);
      };
    }
  }, [dispatch, router, BROWSER_MODE]);

  useEffect(() => {
    let uuid = getLocalStorageItem('classu_uuid');
    if (!uuidValidate(uuid)) {
      const newUuid = uuidV4();
      localStorage.setItem('classu_uuid', newUuid);
      dispatch(setUuid(newUuid));
      return;
    }
    if (uuid) {
      dispatch(setUuid(uuid));
    }
    if (!uuid) {
      const newUuid = uuidV4();
      localStorage.setItem('classu_uuid', newUuid);
      dispatch(setUuid(newUuid));
    }
  }, [dispatch]);

  useEffect(() => {
    if (BROWSER_MODE === 'READY') {
      dispatch(postDataMiningDataLoad());
    }
  }, [BROWSER_MODE, dispatch]);
};

export { useDataMining, useSetURLData, useAttachPageEvent };
