import request from 'api';

/* =============================== GET ================================ */

/**
 * 최근 검색어
 * @version 3
 * @param {number} userId 유저 ID
 */
export const getRecentKeyword = userId => {
  return request.get(`/enfit2/api/v1/log/search/${userId}`);
};

/**
 * 검색 랭킹
 * @version 1
 */
export const getSearchRanking = () => {
  return request.get(`/enfit2/api/v1/search_rank`);
};

/* =============================== POST =============================== */

/**
 * 클래스 검색
 * @param {number} userId 유저 Id
 * @param {FormData} data
 * @param {string} data.keyword 검색어
 * @param {import('types/slices/search').CategorySort} data.order 정렬
 * @param {number} data.start
 * @param {number} data.end
 */
export const postClassSearch = (userId, data) => {
  const url = userId ? `/enfit2/api/v2/class/search/${userId}` : `/enfit2/api/v2/class/search`;
  return request.post(url, data);
};

/**
 * 클래스 자동완성
 * @version 1
 * @param {object} data
 * @param {string} data.keyword 검색어
 */
export const postAutoCompleteKeyword = data => {
  return request.post(`/enfit2/api/v1/class/search_suggest`, data);
};

/* =============================== PUT ================================ */

/* ============================== DELETE ============================== */

/**
 * 최근 클래스 검색어 제거
 * @version 1
 * @param {object} props
 * @param {number} props.userId 유저 ID
 * @param {string} props.keywordId 검색어 ID
 */
export const deleteRecentKeyword = ({ userId, keywordId }) => {
  return request.delete(`/enfit2/api/v1/log/search/${userId}/${keywordId}`);
};

/**
 * 최근 클래스 검색어 전체 제거
 * @version 1
 * @param {number} userId 유저 ID
 */
export const deleteAllRecentKeyword = userId => {
  return request.delete(`/enfit2/api/v1/log/search/${userId}`);
};
