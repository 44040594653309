import dynamic from 'next/dynamic';

/** 빨라야 하는 모달 */
import SearchModal from 'components/organisms/modals/Search'


/** 느려도 되는 모달 */
export const MediaPreview = dynamic(() => import('components/organisms/modals/MediaPreview'));
export const Login = dynamic(() => import('components/organisms/modals/Login'));
export const ClassShare = dynamic(() => import('components/organisms/modals/ClassShare'));
export const Search = SearchModal;
export const Share = dynamic(() => import('components/organisms/modals/Share'));
export const RequirementInfo = dynamic(() => import('components/organisms/modals/RequirementInfo'));
/* ------------------------- Menu ------------------------- */
export const MediaUpload = dynamic(() => import('components/organisms/modals/menu/MediaUpload'));
export const FeedOption = dynamic(() => import('components/organisms/modals/menu/FeedOption'));
/* ------------------------ Dialog ------------------------ */
export const ConfirmDialog = dynamic(() => import('components/organisms/modals/dialog/Confirm'));
export const AlertDialog = dynamic(() => import('components/organisms/modals/dialog/Alert'))
