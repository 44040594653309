import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial State
 * @type {import('types/slices/class').InitialState}
 */
const initialState = {
  info: {
    isLoaded: false,
  },
  selected: {
    package: [],
  },
};

/**
 * Slice
 */
const classSlice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    /** @dispatch 클래스 정보 갱신 */
    updateClassInfo(state, action) {
      state.info = {
        ...state.info,
        ...action.payload,
        isLoaded: true,
      };
    },
    /** @dispatch 현재 선택된 클래스 갱신 */
    updateSelectedClass(state, action) {
      state.selected = {
        ...state.selected,
        ...action.payload,
      };
    },
  },
});

export const { updateClassInfo, updateSelectedClass, updateTakingClassList } = classSlice.actions;

export default classSlice.reducer;
