import { useEffect } from 'react';
// hooks
import { useSelector } from 'hooks/common/useStore';

const Snippets = {
  tags: (id: string) => {
    const iframe = `<iframe
      src="https://www.googletagmanager.com/ns.html?id=${id}"
      height="0"
      width="0"
      style={{
        display: "none",
        visibility: "hidden",
      }}
    />`;

    const script = `
      (function(w,d,s,l,i) {
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${id}');`;

    return {
      iframe,
      script,
    };
  },
};

const TagManager = {
  gtm: function ({ id }: { id: string }) {
    const snippets = Snippets.tags(id);
    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };
    const script = () => {
      const script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };
    return {
      noScript,
      script,
    };
  },
  initialize: function ({ gtmId }: { gtmId: string }): void {
    const gtm = this.gtm({ id: gtmId });
    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
};

/**
 * Google TagManager
 */
export const GoogleTagManager = () => {
  const userInfo = useSelector(state => state.user.info);

  useEffect(() => {
    if (!userInfo.isLoaded) {
      return;
    }

    // selper
    TagManager.initialize({ gtmId: 'GTM-KT6FM2N' });
    TagManager.initialize({ gtmId: 'GTM-TWM4BVS' });
    TagManager.initialize({ gtmId: 'GTM-5GZXBL8' }); // charile 요청건
  }, [userInfo]);

  return null;
};

const adsScript = [
  'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/831064453/?random=1627004057884&amp;cv=9&amp;fst=1627004057884&amp;num=1&amp;guid=ON&amp;resp=GooglemKTybQhCsO&amp;u_h=900&amp;u_w=1440&amp;u_ah=875&amp;u_aw=1440&amp;u_cd=30&amp;u_his=2&amp;u_tz=540&amp;u_java=false&amp;u_nplug=2&amp;u_nmime=2&amp;gtm=2wg7l1&amp;sendb=1&amp;ig=1&amp;frm=0&amp;url=https%3A%2F%2Fwww.classu.co.kr%2F&amp;tiba=%ED%81%B4%EB%9E%98%EC%8A%A4%EC%9C%A0%20%3A%3A%20%ED%95%A8%EA%BB%98%20%EB%B0%B0%EC%9A%B0%EB%8A%94%20%EC%98%A8%EB%9D%BC%EC%9D%B8%20%ED%81%B4%EB%9E%98%EC%8A%A4%20%EA%B0%95%EC%9D%98%2C%20%EC%88%98%EC%97%85%2C%20%EA%B0%95%EC%A2%8C%20-%20%ED%81%B4%EB%9E%98&amp;hn=www.googleadservices.com&amp;async=1&amp;rfmt=3&amp;fmt=4',
  'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/536543892/?random=1627004057892&amp;cv=9&amp;fst=1627004057892&amp;num=1&amp;bg=ffffff&amp;guid=ON&amp;resp=GooglemKTybQhCsO&amp;u_h=900&amp;u_w=1440&amp;u_ah=875&amp;u_aw=1440&amp;u_cd=30&amp;u_his=2&amp;u_tz=540&amp;u_java=false&amp;u_nplug=2&amp;u_nmime=2&amp;gtm=2oa7l1&amp;sendb=1&amp;ig=1&amp;data=event%3Dgtag.config&amp;frm=0&amp;url=https%3A%2F%2Fwww.classu.co.kr%2F&amp;tiba=%ED%81%B4%EB%9E%98%EC%8A%A4%EC%9C%A0%20%3A%3A%20%ED%95%A8%EA%BB%98%20%EB%B0%B0%EC%9A%B0%EB%8A%94%20%EC%98%A8%EB%9D%BC%EC%9D%B8%20%ED%81%B4%EB%9E%98%EC%8A%A4%20%EA%B0%95%EC%9D%98%2C%20%EC%88%98%EC%97%85%2C%20%EA%B0%95%EC%A2%8C%20-%20%ED%81%B4%EB%9E%98&amp;hn=www.googleadservices.com&amp;async=1&amp;rfmt=3&amp;fmt=4',
];

/**
 * Google Ads
 */
// TODO 정체가 무엇인가..?
export const GoogleAds = () => {
  const userInfo = useSelector(state => state.user.info);

  const initialize = () => {
    for (let i = 0, len = adsScript.length; i < len; i++) {
      const script = document.createElement('script');
      script.src = adsScript[i];
      // TODO MIME Types
      script.type = 'text/javascript';
      document.head.appendChild(script);
    }
  };

  useEffect(() => {
    if (!userInfo.isLoaded) {
      return;
    }

    initialize();
  }, [userInfo]);
};
