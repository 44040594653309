import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
// utils
import deviceChecker from 'lib/utils/deviceChecker';

const useWebview = () => {
  const { route } = useRouter();
  const [isWebview, setIsWebview] = useState(false);
  const [isStableScroll, setIsStableScroll] = useState(false);

  /** height: 100% 적용하지 않는 웹뷰 페이지 */
  const NOT_APPLY_ROUTE = useRef([
    '/webview/alarm/class',
    '/webview/alarm/class/[classId]',
    '/class/[classId]/review',
    '/class/[classId]/review/[reviewId]',
  ]);

  useEffect(() => {
    const device = deviceChecker();
    const isWebview = device.app();

    if (isWebview) {
      setIsWebview(true);
    } else {
      setIsWebview(false);
    }

    if (isWebview && !~NOT_APPLY_ROUTE.current.indexOf(route)) {
      setIsStableScroll(false);
    } else if (isWebview) {
      setIsStableScroll(true);
    } else {
      setIsStableScroll(false);
    }
  }, [route]);

  useEffect(() => {
    if (isWebview) {
      document.querySelector('body').classList.add('noselect');
    } else {
      document.querySelector('body').classList.remove('noselect');
    }

    if (isStableScroll) {
      document.querySelector('html').classList.remove('webview');
      document.querySelector('body').classList.remove('webview');
    } else if (isWebview) {
      document.querySelector('html').classList.add('webview');
      document.querySelector('body').classList.add('webview');
    }
  }, [isWebview, isStableScroll]);

  return { isWebview };
};

export default useWebview;
