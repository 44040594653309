import styled from 'styled-components';
// styles
import { mediaQuery, palette, zIndex } from 'cds/styles';

/** 헤더 기본 레이아웃 */
const BaseHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 56px;
  z-index: ${zIndex.header};
  background-color: ${palette.white};

  ${mediaQuery.large} {
    height: 84px;
  }
`;

/** 헤더 공간 여백 */
export const HeaderBlank = styled.div`
  height: 56px;

  ${mediaQuery.large} {
    height: 84px;
  }
`;

export default BaseHeader;
