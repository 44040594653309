import { useState, useEffect, useMemo, useRef } from 'react';

/**
 * 이미지 지연 로드
 * @param {object} props
 * @param {string} props.src
 * @returns {
 * 	imageRef
 * 	imageSrc,
 * }
 */
const useLazyImageObserver = ({ src }) => {
  const [imageSrc, setImageSrc] = useState('');
  const imageRef = useRef(null);
  const imageURL = useMemo(() => imageSrc, [imageSrc]);

  useEffect(() => {
    const { current } = imageRef;
    if (!current) {
      return;
    }

    const options = { threshold: 0.1 };
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setImageSrc(src);
        observer.unobserve(current);
      }
    }, options);

    observer.observe(current);

    return () => {
      observer.disconnect();
    };
  }, [src]);

  return {
    imageRef,
    imageSrc: imageURL,
  };
};

export default useLazyImageObserver;
